import React, { useRef, useState, useEffect, ChangeEvent } from 'react';

import { Box, Tooltip, useTheme, TextField, SelectChangeEvent } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import { setErrorList } from '../../../redux/slices/formBuilder';
import validateIsEmail from '../../../@types/forms/utils/validateIsEmail';
import validateIsNumber from '../../../@types/forms/utils/validateIsNumber';
import validateIsRequired from '../../../@types/forms/utils/validateIsRequired';
import validateNumberOfCharacters from '../../../@types/forms/utils/validateNumberOfCharacters';
import { FormElementValidationType } from '../../../@types/forms/enums/FormElementValidationType';
import { ElementProps, ErrorsListProps, ElementValidationProps } from '../../../@types/forms/types';

//-----------------------------------------------------

type InputFieldElementProps = {
  isChild?: boolean;
  el: ElementProps;
  scaledDimensions: {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    fontSize: number;
  };
  elementContentValue?: string[] | undefined;
  handleTextAlign: (alignEnum: number) => string;
  handleInputFieldChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
    el: ElementProps
  ) => void;
  pageNumberScale: number;
};

const InputFieldElement: React.FC<InputFieldElementProps> = ({
  isChild,
  el,
  scaledDimensions,
  elementContentValue,
  handleTextAlign,
  handleInputFieldChange,
  pageNumberScale,
}) => {
  const { t } = useLocales();
  const [value, setValue] = useState<string | undefined>(undefined);
  const [elementErrorsList, setElementErrorsList] = useState<ErrorsListProps[]>([]);
  const theme = useTheme();

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const labelRef = useRef<HTMLSpanElement | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const data: ElementValidationProps = { el, errorMessage: '', value, elementErrorsList };
    const newErrorList: ErrorsListProps[] = [];

    if (el.required) {
      const newError = validateIsRequired({
        ...data,
        errorMessage: t('forms.validationErrorMessage.fieldRequired'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    if (el?.validations?.value === FormElementValidationType.OnlyNumbers) {
      const newError = validateIsNumber({
        ...data,
        errorMessage: t('forms.validationErrorMessage.notNumber'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    if (
      el?.validations?.value === FormElementValidationType.Email &&
      value !== undefined &&
      value !== ''
    ) {
      const newError = validateIsEmail({
        ...data,
        errorMessage: t('forms.validationErrorMessage.invalidEmailFormat'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    if (
      el?.validations?.value === FormElementValidationType.NumberOfCharacters &&
      el?.numberOfCharacters
    ) {
      const min = el?.numberOfCharacters[0];
      const max = el?.numberOfCharacters[1];
      let errorMessage = '';

      if (min === max) {
        errorMessage = `${t('forms.validationErrorMessage.InvalidNumberOfCharacters')} ${t(
          'forms.validationErrorMessage.InvalidNumberOfCharactersStrictly',
          {
            min,
          }
        )}`;
      } else {
        errorMessage = `${t('forms.validationErrorMessage.InvalidNumberOfCharacters')} ${t(
          'forms.validationErrorMessage.InvalidNumberOfCharactersMinMax',
          {
            min,
            max,
          }
        )}`;
      }

      const newError = validateNumberOfCharacters({
        ...data,
        errorMessage,
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    setElementErrorsList(newErrorList);
  }, [value]);

  useEffect(() => {
    if (elementErrorsList) {
      setErrorList({ elementID: el.id, errors: elementErrorsList });
    }
  }, [elementErrorsList]);

  useEffect(() => {
    if (elementContentValue) {
      setValue(elementContentValue[0]);
    }
  }, []);

  useEffect(() => {
    if (labelRef.current && inputRef.current) {
      const labelWidth = labelRef.current.offsetWidth;
      const inputWidth = inputRef.current.offsetWidth;

      setShowTooltip(labelWidth > inputWidth);
    }
  }, [el.label]);

  const handleFocus = () => {
    if (showTooltip) {
      setTooltipOpen(true);
    }
  };

  const handleBlur = () => {
    setTooltipOpen(false);
  };

  return (
    <Box
      ref={inputRef}
      sx={{
        backgroundColor: theme.palette.info.lighter,
        borderRadius: 1,
        width: '100%',
      }}
    >
      <Tooltip
        title={el.label}
        open={tooltipOpen}
        onClose={() => setTooltipOpen(false)}
        disableHoverListener={!showTooltip}
        placement="top-start"
      >
        <TextField
          disabled={isChild}
          value={value}
          fullWidth
          multiline
          error={elementErrorsList.length > 0}
          helperText={elementErrorsList.length > 0 ? elementErrorsList[0].errorMessage : null}
          size="small"
          inputProps={{
            maxLength:
              el.validations.value !== 0 && el.numberOfCharacters ? el.numberOfCharacters[1] : 1000,
            sx: {
              color: el.color,
              fontSize: `${el.fontSize * pageNumberScale}px`,
              fontFamily: `${el.font}`,
              textAlign: handleTextAlign(el.textAlignment),
              fontWeight: el.textStyle.includes('Bold') ? 'Bold' : 'Regular',
              fontStyle: el.textStyle.includes('Italic') ? 'italic' : 'normal',
              textDecoration: el.textDecoration === 1 ? 'underline' : '',
            },
          }}
          InputLabelProps={{ shrink: !!value }}
          id={el.id}
          label={
              <span ref={labelRef}>
                {el.label}
              </span>
          }
          variant="standard"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={(event) => {
            handleInputFieldChange && handleInputFieldChange(event, el);
            setValue(event.target.value);
          }}
          sx={{
            width: '100%',
            maxWidth: '100%',
          }}
        />
      </Tooltip>
    </Box>
  );
};

export default InputFieldElement;
