import React from 'react';

import {
    Box,
    useTheme,
    Typography,
  } from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import { BorderLinearProgress } from './BorderLinearProgress';

  //------------------------------------

type AuthSignLinearDataProps = {
    title: string;
    actionTypeMobileIdnum: number;
    actionTypeSmartIdnum: number
}

const AuthSignLinearData: React.FC<AuthSignLinearDataProps> = ({
    title,
    actionTypeMobileIdnum, 
    actionTypeSmartIdnum, 
  }) => {
    const { t } = useLocales();
    const theme = useTheme();
    const isMobile = useResponsive('down', 'sm');

    const percent = (total: number, num: number): number => {
        if (total !== 0 && num !== 0) {
          return Math.round((num * 100) / total);
        }
        return 0;
      };

    const authMobId = percent(
        actionTypeMobileIdnum + actionTypeSmartIdnum,
        actionTypeMobileIdnum
      );

    const linearDataText = (color: string, text: string, count: number) => {
        const circle = (color: string) => (
          <Box sx={{ background: color, height: 10, width: 10, borderRadius: 5, mr: 2 }} />
        );
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {circle(color)}
            <Typography variant="body2" component="div" color="text.secondary">
              {text}: {count}
            </Typography>
          </Box>
        );
      };

  return (
 
    <Box sx={{ flex: 1 }}>
        <Typography
            variant="body1"
            component="div"
            color="text.secondary"
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            {title}
        </Typography>

        <Box sx={{ my: 2, mx: isMobile ? 3 : 4 }}>
            <BorderLinearProgress variant="determinate" value={authMobId} />

            <Box sx={{ mt: 3 }}>
            {linearDataText(
                theme.palette.primary.light,
                t('dashboard.mobileId'),
                actionTypeMobileIdnum
            )}

            {linearDataText(
                theme.palette.secondary.light,
                t('dashboard.smartId'),
                actionTypeSmartIdnum
            )}
            </Box>
        </Box>
    </Box>

   
  )
}

export default AuthSignLinearData;