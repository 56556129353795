import { DlxIntegrationType } from "./types";

export class Tenant {
  id: number;

  name: string;

  key: string;

  domain: string;

  doclogixDomain: string;

  doclogixNTLMAuthUsername: string;

  doclogixNTLMAuthPassword: string;

  doclogixApiKey: string;

  isDeactivated: boolean;

  moduleIds: number[];

  formTemplateIds: number[];

  signaturesLimit: number;

  passwordExpirationHours: number;

  clientId: string;

  grantType: string;

  integrationType: DlxIntegrationType;

  scope: string;

  azureTenantId: string;

  azureAccountPassword: string;

  azureAccountUsername: string;

  httpSecure: boolean;

  eparakstsClientId: string;

  eparakstsClientSecret: string;

  authenticationTrackerDto: {
    smartID: number;
    mobileID: number;
  };

  signingTrackerDto: {
    smartID: number;
    mobileID: number;
  };

  parseJson(json: any): Tenant | null {
    if (json === null || json === undefined) {
      return null;
    }

    const model: Tenant = new Tenant();

    model.id = json.id;
    model.name = json.name;
    model.key = json.key;
    model.domain = json.domain;
    model.doclogixDomain = json.doclogixDomain;
    model.doclogixNTLMAuthUsername = json.doclogixNTLMAuthUsername;
    model.doclogixNTLMAuthPassword = json.doclogixNTLMAuthPassword;
    model.doclogixApiKey = json.doclogixApiKey;
    model.isDeactivated = json.isDeactivated;
    model.moduleIds = json.moduleIds;
    model.formTemplateIds = json.formTemplateIds;
    model.signaturesLimit = json.signaturesLimit;
    model.passwordExpirationHours = json.passwordExpirationHours;
    model.clientId = json.clientId;
    model.grantType = json.grantType;
    model.integrationType = json.integrationType;
    model.scope = json.scope;
    model.azureTenantId = json.azureTenantId;
    model.azureAccountPassword = json.azureAccountPassword;
    model.azureAccountUsername = json.azureAccountUsername;
    model.httpSecure = json.httpSecure;
    model.eparakstsClientId = json.eparakstsClientId;
    model.eparakstsClientSecret = json.eparakstsClientSecret;
    model.authenticationTrackerDto = json.authenticationTrackerDto;
    model.signingTrackerDto = json.signingTrackerDto;

    return model;
  }
}
