// NEW FORM BUILDER COMPONENT

import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from '../store';
import { HOST_API } from '../../config';
import axios from '../../utils/httpClients/axios';
import { PagedResponse } from '../../@types/shared/pagedResponse';
import { FormTemplatesFilter } from '../../@types/form-templates/formTemplatesFilter';
import {
  FormProps,
  FormTypeProps,
  FormBuilderState,
  ElementErrorsProps,
} from '../../@types/forms/types';

// ----------------------------------------------------------------------

const initialState: FormBuilderState = {
  isLoading: false,
  error: null,
  forms: null,
  types: null,
  file: null,
  selectedId: null,
  form: null,
  errorList: [],
};

const slice = createSlice({
  name: 'formBuilder',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.forms = initialState.forms;
      state.file = initialState.file;
      state.selectedId = initialState.selectedId;
      state.form = initialState.form;
      state.types = initialState.types;
      state.errorList = initialState.errorList;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getFormsSuccess(state, action) {
      state.isLoading = false;
      state.forms = action.payload;
    },

    getTypesSuccess(state, action) {
      state.isLoading = false;
      state.types = action.payload;
    },

    getFormSuccess(state, action) {
      state.isLoading = false;
      state.selectedId = action.payload.id;
      state.form = action.payload;
    },

    getFormsError(state, action) {
      state.isLoading = false;
      state.forms = initialState.forms;
      state.error = action.payload;
    },

    getTypesError(state, action) {
      state.isLoading = false;
      state.types = initialState.types;
      state.error = action.payload;
    },

    getFileSuccess(state, action) {
      state.isLoading = false;
      state.file = action.payload;
    },

    getFileError(state, action) {
      state.isLoading = false;
      state.file = null;
      state.error = action.payload;
    },

    setErrorList(state, action) {
      const elementId = action.payload.elementID;
      const newErrorList = state.errorList.filter((error) => error.elementID !== elementId);
      const elementErrorList = action.payload.errors;

      if (elementErrorList.length > 0) {
        state.errorList = [...newErrorList, ...elementErrorList];
      } else {
        state.errorList = [...newErrorList];
      }
    },

    clearErrorList(state) {
      state.errorList = initialState.errorList;
    },
  },
});

// Reducer
export default slice.reducer;

export const clearFormsState = () => {
  dispatch(slice.actions.clearState());
};

export const setStartLoading = () => {
  dispatch(slice.actions.startLoading());
};

export const setFinishLoading = () => {
  dispatch(slice.actions.finishLoading());
};

export const getForm = async (formTemplateId: number) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get<FormProps>(
      `${HOST_API}FormBuilder/template/${formTemplateId}`
    );

    dispatch(slice.actions.getFormSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
};

export const getForms = async (filter: FormTemplatesFilter) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get<PagedResponse<FormProps>>(
      `${HOST_API}FormBuilder/templates/list`,
      { params: { ...filter } }
    );

    dispatch(slice.actions.getFormsSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
};

export const getTypes = async (filter: FormTemplatesFilter) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get<PagedResponse<FormTypeProps>>(
      `${HOST_API}FormBuilder/type/list`,
      { params: { ...filter } }
    );

    dispatch(slice.actions.getTypesSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
};

export const getFile = async (id: number | null) => {
  if (id) {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API}FormBuilder/template/${id}/file`, {
        responseType: 'blob',
      });
      const file = new File([response.data], `form-${id}.pdf`, { type: 'application/pdf' });

      dispatch(slice.actions.getFileSuccess(file));
    } catch (error) {
      dispatch(slice.actions.getFileError(error));
    }
  } else {
    dispatch(slice.actions.getFileError('documents.errors.documentNotFound'));
  }
};

export const removeFormTemplate = async (id: number) => {
  dispatch(slice.actions.startLoading());
  try {
    await axios.delete(`${HOST_API}FormBuilder/template/${id}`);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
};

export const removeFormType = async (id: number) => {
  dispatch(slice.actions.startLoading());
  try {
    await axios.delete(`${HOST_API}FormBuilder/type/${id}`);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
};

export const setErrorList = (errors: ElementErrorsProps) => {
  try {
    dispatch(slice.actions.setErrorList(errors));
  } catch (e) {
    console.error(e);
  }
};

export const clearFormErrorList = () => {
  dispatch(slice.actions.clearErrorList());
};
