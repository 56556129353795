import { CANVAS_TEXT_SIZE } from '../../../@types/documents/constants/SignatureConstants';

//-------------------------------------------------------

const drawTextOnCanvas = (text: string, inkColor: string) => {
  const font = `italic ${CANVAS_TEXT_SIZE}px 'Brush Script MT', cursive`;

  const tempCanvas = document.createElement('canvas');
  const tempCtx = tempCanvas.getContext('2d');

  if (tempCtx) {
    tempCtx.font = font;

    const { fontBoundingBoxAscent } = tempCtx.measureText(text);
    const { fontBoundingBoxDescent } = tempCtx.measureText(text);
    const { actualBoundingBoxLeft } = tempCtx.measureText(text);
    const { actualBoundingBoxRight } = tempCtx.measureText(text);

    const canvas = document.createElement('canvas');
    canvas.width = (actualBoundingBoxLeft + actualBoundingBoxRight) * 1.02;
    canvas.height = fontBoundingBoxAscent + fontBoundingBoxDescent;

    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.font = font;
      ctx.fillStyle = inkColor;
      ctx.fontKerning = 'normal';
      ctx.imageSmoothingQuality = 'high';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      ctx.fillText(text, canvas.width / 2, (canvas.height + fontBoundingBoxDescent) / 2);

      return canvas;
    }
  }

  return tempCanvas;
};

export default drawTextOnCanvas;
