import React, { useRef, useState } from 'react';

import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { Box, Button, MenuItem, IconButton, Typography } from '@mui/material';

import useLocales from '../../hooks/useLocales';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import DLXDriveSortByItems from '../../@types/dlx-drive/enums/DLXDriveSortByItems';

// ----------------------------------------------------------------

type DLXDriveSortActionsProps = {
  sortingColumn: DLXDriveSortByItems;
  setSortingColumn: (value: DLXDriveSortByItems) => void;
  sortDescending: boolean;
  setSortDescending: (value: boolean) => void;
};

const DLXDriveSortActions: React.FC<DLXDriveSortActionsProps> = ({
  sortingColumn,
  setSortingColumn,
  sortDescending,
  setSortDescending,
}) => {
  const { t } = useLocales();
  const [open, setOpen] = useState(false);

  const sortByRef = useRef<HTMLElement>(null);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSortByChange = (value: DLXDriveSortByItems) => {
    setSortingColumn(value);
    setOpen(false);
  };

  const getSortByLabel = () => {
    switch (sortingColumn) {
      case DLXDriveSortByItems.Name:
        return 'dlxDrive.sortBy.Name';
      case DLXDriveSortByItems.Modified:
        return 'dlxDrive.sortBy.Modified';
      case DLXDriveSortByItems.Prefix:
        return 'dlxDrive.sortBy.Prefix';
      case DLXDriveSortByItems.Status:
        return 'dlxDrive.sortBy.Status';
      case DLXDriveSortByItems.Author:
        return 'dlxDrive.sortBy.Author';
      case DLXDriveSortByItems.Tags:
        return 'dlxDrive.sortBy.Tags';
      default:
        return '';
    }
  };

  return (
    <>
      <Button
        color="secondary"
        variant="text"
        sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }}
      >
        <Box
          ref={sortByRef}
          onClick={handleOpen}
          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          id="MSF2c1"
        >
          <FilterListRoundedIcon />

          <Typography>{`${t('dlxDrive.sortBy.sortBy')}: `}</Typography>
          <Typography sx={{ fontWeight: 900 }}>{`${t(getSortByLabel())}`}</Typography>
        </Box>

        <IconButton onClick={() => setSortDescending(!sortDescending)} size="small" id="tl0abl">
          {sortDescending ? <ArrowDownwardRoundedIcon /> : <ArrowUpwardRoundedIcon />}
        </IconButton>
      </Button>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={sortByRef.current}
        sx={{
          width:
            sortByRef.current && sortByRef.current.clientWidth
              ? sortByRef.current.clientWidth
              : '300px',
          ml: '-1px',
        }}
      >
        <Scrollbar sx={{ maxHeight: '248px' }}>
          <MenuItem
            onClick={() => handleSortByChange(DLXDriveSortByItems.Name)}
            sx={{ py: 1, px: 2, borderRadius: 1, m: 1 }}
            id="wKynL1"
          >
            <Typography noWrap>{t('dlxDrive.sortBy.Name')}</Typography>
          </MenuItem>

          <MenuItem
            onClick={() => handleSortByChange(DLXDriveSortByItems.Modified)}
            sx={{ py: 1, px: 2, borderRadius: 1, m: 1 }}
            id="WERFCr"
          >
            <Typography noWrap>{t('dlxDrive.sortBy.Modified')}</Typography>
          </MenuItem>

          <MenuItem
            onClick={() => handleSortByChange(DLXDriveSortByItems.Prefix)}
            sx={{ py: 1, px: 2, borderRadius: 1, m: 1 }}
            id="chaEk1"
          >
            <Typography noWrap>{t('dlxDrive.sortBy.Prefix')}</Typography>
          </MenuItem>

          {/* --- Due to slow performance, sorting by status is temporarily disabled ----*/}
          {/* <MenuItem */}
          {/*  onClick={() => handleSortByChange(DLXDriveSortByItems.Status)} */}
          {/*  sx={{ py: 1, px: 2, borderRadius: 1, m: 1 }} */}
          {/* > */}
          {/*  <Typography noWrap>{t('dlxDrive.sortBy.Status')}</Typography> */}
          {/* </MenuItem> */}

          <MenuItem
            onClick={() => handleSortByChange(DLXDriveSortByItems.Author)}
            sx={{ py: 1, px: 2, borderRadius: 1, m: 1 }}
            id="cXaQf1"
          >
            <Typography noWrap>{t('dlxDrive.sortBy.Author')}</Typography>
          </MenuItem>

          {/* --- Temporarily disabled due to lack of rules on how to sort ----*/}
          {/* <MenuItem */}
          {/*  onClick={() => handleSortByChange(DLXDriveSortByItems.Tags)} */}
          {/*  sx={{ py: 1, px: 2, borderRadius: 1, m: 1 }} */}
          {/* > */}
          {/*  <Typography noWrap>{t('dlxDrive.sortBy.Tags')}</Typography> */}
          {/* </MenuItem> */}
        </Scrollbar>
      </MenuPopover>
    </>
  );
};

export default DLXDriveSortActions;
