import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { LoadingButton } from '@mui/lab';
import { Dialog, TextField, DialogTitle, DialogActions, DialogContent } from '@mui/material';

import { PATH_ADMIN } from 'src/routes/paths';

import useLocales from 'src/hooks/useLocales';
import useNotifications from 'src/hooks/useNotifications';

import { useSelector } from 'src/redux/store';
import { FormProps } from 'src/@types/forms/types';
import { createFormCopyById } from 'src/services/formService';

//-------------------------------------------------------

type DuplicateFormDialogProps = {
  isDuplicateDialogOpen: boolean;
  setIsDuplicateDialogOpen: (value: boolean) => void;
  formTemplate: FormProps;
  handleModalClose: () => void;
};

const DuplicateFormDialog: React.FC<DuplicateFormDialogProps> = ({
  isDuplicateDialogOpen,
  setIsDuplicateDialogOpen,
  formTemplate,
  handleModalClose,
}) => {
  const { t } = useLocales();
  const maxLengthName = 100;
  const navigate = useNavigate();
  const { forms } = useSelector((state) => state.formBuilder);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSuccess } = useNotifications();
  const defaultFormName = () => {
    const formsNames = forms?.items.map((item) => item.name);
    let copyName = '';

    if (formsNames && formsNames.includes(formTemplate.name)) {
      if (formTemplate.name.includes('(copy)')) {
        const regex = /(.*\(copy\))(?:\s\((\d+)\))?$/;
        const match = formTemplate.name.match(regex);
        if (match) {
          const baseName = match[1];
          let number = match[2] ? parseInt(match[2], 10) : 1;

          let newFormCopyName: string;
          do {
            number++;
            newFormCopyName = `${baseName} (${number})`;
          } while (formsNames && formsNames.includes(newFormCopyName));

          copyName = newFormCopyName;
        }
      } else {
        copyName = `${formTemplate.name} (copy)`;
        let number = 1;

        while (formsNames.includes(copyName)) {
          number++;
          copyName = `${formTemplate.name} (copy) (${number})`;
        }
      }
    }

    return copyName;
  };
  const defaultName = defaultFormName();
  const [formCopyName, setFormCopyName] = useState(defaultName);

  const handleSave = async () => {
    const newCopyName = formCopyName !== '' ? formCopyName : defaultFormName();

    const modelForCopy = {
      copyFromId: formTemplate.id,
      name: newCopyName,
      formTypeId: formTemplate.formTypeId,
    };

    try {
      setIsLoading(true);
      await createFormCopyById(modelForCopy);
      enqueueSuccess(t('forms.snackbar.formDuplicated'));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsDuplicateDialogOpen(false);
      handleModalClose();
      navigate(PATH_ADMIN.forms);
      setFormCopyName(defaultName);
    }

    setIsDuplicateDialogOpen(false);
  };

  const handleClose = () => {
    setIsDuplicateDialogOpen(false);
  };

  return (
    <Dialog open={isDuplicateDialogOpen} fullWidth maxWidth="xs" onClose={handleClose}>
      <DialogTitle>{t('formTemplates.duplicateForm')}</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          id="copied-formtemplate-name"
          label={t('formTemplates.formTemplateName')}
          variant="outlined"
          size="small"
          fullWidth
          value={formCopyName}
          onChange={(e) => setFormCopyName(e.target.value.replaceAll('/', ''))}
          inputProps={{ maxLength: maxLengthName }}
          helperText={
            formCopyName.length === maxLengthName
              ? `${t('dlxDrive.dialogs.maxFolderNameLengthReached', { maxLengthName })}`
              : ''
          }
        />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="vlgkc7ur">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          disabled={formCopyName.length === 0}
          loading={isLoading}
          variant="contained"
          sx={{ lineHeight: '1rem' }}
          onClick={handleSave}
          id="au8qswkg"
        >
          {`${t('formTemplates.saveAsNew')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default DuplicateFormDialog;
