export enum ModuleTypes {
  Standard = 1,
  ComposeDocuments = 2,
  ManageSigners = 3,
  Tags = 4,
  HR = 5,
  Accounting = 6,
  CustomJobTitles = 7,
  AddressBook = 8,
  VoidDocuments = 9,
  EmailLogin = 10,
  Delegation = 11,
  SendingPrivateMessageToSigners = 12,
  Dashboard = 13,
  AutomaticDeletionCompletedDocuments = 14,
  EparakstLogin = 15,
  FoldersModule = 16,
  FormsModule = 17,
  StatisticsModule = 18,
  StaticFieldModule = 19,
  WorkflowsModule = 20,
  OldFormsModule = 21,
  ExternalSharingModule = 22,
  MassApprovalModule = 23,
  RemindersNotificationsModule = 24,
  SmartIDLogin = 25,
  MobileIDLogin = 26,
  Calendar = 27,
  AccompanyingDocuments = 28,
  SigningComment = 29,
  EparakstsBulkSignModule = 30,
}
