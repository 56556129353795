import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import { Box, Divider } from '@mui/material';
import { RichTreeView } from '@mui/x-tree-view';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import { TreeItem2, TreeItem2Props, TreeItem2SlotProps } from '@mui/x-tree-view/TreeItem2';

import useLocales from '../../../hooks/useLocales';
import folderService from '../../../services/folderService';
import { FolderTreeProp } from '../../../@types/folders/types';

// ----------------------------------------------------------------

type FolderTreeProps = {
  setIsDrawerOpen: (isOpen: boolean) => void;
};

const CustomTreeItem = React.forwardRef((props: TreeItem2Props, ref: React.Ref<HTMLLIElement>) => (
  <TreeItem2
    {...props}
    ref={ref}
    slotProps={
      {
        checkbox: {
          size: 'small',
          icon: <FolderRoundedIcon fontSize="medium" sx={{ color: '#FFCA28' }} />,
          checkedIcon: <FolderOpenRoundedIcon fontSize="medium" sx={{ color: '#FFCA28' }} />,
        },
      } as TreeItem2SlotProps
    }
  />
));

const FolderTree: React.FC<FolderTreeProps> = ({ setIsDrawerOpen }) => {
  const { t } = useLocales();
  const [folderTree, setFolderTree] = useState<FolderTreeProp[]>([]);
  const [defaultExpandedItems, setDefaultExpandedItems] = useState<string[]>([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const isItemDisabled = (item: FolderTreeProp) => item.disabled;

  const handleItemClick = (itemId: any) => {
    navigate(`/folders/${itemId}/1`);
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    folderService.getFolderTree().then((data) => {
      const newDefaultExpandedItems: string[] = [];

      const registerItemId = (item: FolderTreeProp) => {
        if (item.children?.length) {
          newDefaultExpandedItems.push(item.id);
          item.children.forEach(registerItemId);
        }
      };

      data.forEach(registerItemId);

      setDefaultExpandedItems(newDefaultExpandedItems);
      setFolderTree(data);
    });
  }, []);

  return (
    <Box
      role="presentation"
      sx={{ minWidth: '300px', maxWidth: '600px', p: 2, display: 'flex', flexDirection: 'column' }}
    >
      <Divider sx={{ mb: 2 }} textAlign="left">
        {t('documentTree.folderTree')}
      </Divider>

      {folderTree.length > 0 && (
        <RichTreeView
          items={folderTree}
          defaultExpandedItems={defaultExpandedItems}
          selectedItems={id}
          isItemDisabled={isItemDisabled}
          checkboxSelection
          onItemClick={(_, itemId) => handleItemClick(itemId)}
          slots={{ item: CustomTreeItem }}
        />
      )}
    </Box>
  );
};

export default FolderTree;
