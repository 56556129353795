import React from 'react';

import { useTheme } from '@mui/material';

import convertTextAlignment from 'src/utils/forms/convertTextAlignment';

import { ElementProps } from 'src/@types/forms/types';

//----------------------------------------------------

type ElementLabelMobileProps ={
    el: ElementProps;
    label:  string | undefined;
    label2?: string | undefined;
    labelArr?: string[] | undefined;
    asChildEl?: boolean;
    pageNumberScale: number;
    isStatic?: boolean;
    isDate?: boolean;
}

const ElementLabelMobile: React.FC<ElementLabelMobileProps> = ({el, label,label2, labelArr, asChildEl, pageNumberScale, isStatic, isDate}) => {
    let strings: string[] = [];
    let dataText: string[] = [];
    let addressBookText: string[] = [];
    const theme = useTheme();
  
    if(!labelArr && !isDate){
      if (label && label.length > 0) {
        strings = label.split('\n');
      } else {
        strings.push(el.label);
      }
    }
  
    if(isDate){
      if(label2 && label){
        dataText = [`${label} — ${label2}`];
      }
      else if(label){
        dataText = [label];
      }
    } 

    if(labelArr && labelArr.length > 0){
      addressBookText = labelArr.map((item, index) => index < labelArr.length - 1 ?  `${item}, ` : `${item}`);
    }

    let texts = isDate ? dataText : labelArr ? addressBookText : strings;

    const textAlignResult: any = convertTextAlignment(el.textAlignment);

    return (
      <div 
        style={{
          width: '100%', 
          height: '100%', 
          zIndex: 1}} 
        className='wrapper-z-index' 
        id={el.id}>
         <div 
            style={{ 
              display: 'flex', 
              flexDirection: isDate || labelArr ? 'row' : 'column', 
              minHeight: `${el.fontSize * pageNumberScale + 8}px`, 
              flexWrap: labelArr ? 'wrap' : 'nowrap',  
              backgroundColor: asChildEl || isStatic ? '' : theme.palette.info.lighter, 
              borderRadius: '4px', 
              padding: '4px' }} >

           { el.hyperlink === undefined &&  texts &&
            texts.map((string, i) => (
              <p
                key={`${el.id}-${i}`}
                style={{
                  fontFamily: `${el.font}`,
                  fontWeight: el.textStyle.includes('Bold') ? 'Bold' : 'Regular',
                  fontStyle: el.textStyle.includes('Italic') ? 'italic' : 'normal',
                  textDecoration: el.textDecoration === 1 ? 'underline' : '',
                  color: el.color,
                  fontSize: `${el.fontSize * pageNumberScale}px`,
                  lineHeight: `${el.fontSize * pageNumberScale}px`,
                  textAlign: textAlignResult,
                // width: '100%',
                  wordWrap: 'break-word',
                  margin: 0,
                  padding: 0,
                  // transform: `rotate(${el.rotate}deg)`,
                }}
              >
                {string}
              </p> 
        ))}

        {
          el.hyperlink !== undefined && (
            <p
                style={{
                  fontFamily: `${el.font}`,
                  fontWeight: el.textStyle.includes('Bold') ? 'Bold' : 'Regular',
                  fontStyle: el.textStyle.includes('Italic') ? 'italic' : 'normal',
                  textDecoration: el.textDecoration === 1 ? 'underline' : '',
                  color: el.color,
                  fontSize: `${el.fontSize * pageNumberScale}px`,
                  lineHeight: `${el.fontSize * pageNumberScale}px`,
                  textAlign: textAlignResult,
                // width: '100%',
                  wordWrap: 'break-word',
                  margin: 0,
                  padding: 0,
                  // transform: `rotate(${el.rotate}deg)`,
                }}
              >
                <a 
                  href={el.hyperlink.url} 
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                   borderBottom: `1px solid ${el.color}`
                  }}
                >
                  {el.hyperlink.text}
                </a>
              </p> 
          )
        }

         </div>
      </div>
   
  )}

export default ElementLabelMobile;