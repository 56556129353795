import SignaturePosition from '../../../@types/documents/enums/SignaturePosition';
import { CANVAS_GAP } from '../../../@types/documents/constants/SignatureConstants';

//-------------------------------------------------------

const combineBottomLeft = (nameCanvas: HTMLCanvasElement, signatureCanvas: HTMLCanvasElement) => {
  const combinedCanvas = document.createElement('canvas');
  combinedCanvas.width = Math.max(nameCanvas.width, signatureCanvas.width);
  combinedCanvas.height = nameCanvas.height + signatureCanvas.height + CANVAS_GAP;

  const combinedCanvasCtx = combinedCanvas.getContext('2d');

  if (combinedCanvasCtx) {
    combinedCanvasCtx.drawImage(nameCanvas, 0, 0);
    combinedCanvasCtx.drawImage(signatureCanvas, 0, nameCanvas.height + CANVAS_GAP);
  }

  return combinedCanvas;
};

const combineLeft = (nameCanvas: HTMLCanvasElement, signatureCanvas: HTMLCanvasElement) => {
  const combinedCanvas = document.createElement('canvas');
  combinedCanvas.width = nameCanvas.width + signatureCanvas.width + CANVAS_GAP;
  combinedCanvas.height = Math.max(nameCanvas.height, signatureCanvas.height);

  const combinedCanvasCtx = combinedCanvas.getContext('2d');

  if (combinedCanvasCtx) {
    combinedCanvasCtx.drawImage(
      nameCanvas,
      0,
      Math.max(nameCanvas.height, signatureCanvas.height) / 2 - nameCanvas.height / 2
    );
    combinedCanvasCtx.drawImage(signatureCanvas, nameCanvas.width + CANVAS_GAP, 0);
  }

  return combinedCanvas;
};

const combineCanvas = (
  nameCanvas: HTMLCanvasElement,
  signatureCanvas: HTMLCanvasElement,
  position: SignaturePosition
) => {
  switch (position) {
    case SignaturePosition.BOTTOM_LEFT:
      return combineBottomLeft(nameCanvas, signatureCanvas);
    case SignaturePosition.LEFT:
      return combineLeft(nameCanvas, signatureCanvas);
    default:
      return combineBottomLeft(nameCanvas, signatureCanvas);
  }
};

export default combineCanvas;
