enum SignaturePosition {
  TOP_LEFT = 0,
  TOP_RIGHT = 1,
  BOTTOM_LEFT = 2,
  BOTTOM_RIGHT = 3,
  CENTER = 4,
  LEFT = 5,
  RIGHT = 6,
  TOP = 7,
  BOTTOM = 8,
}

export default SignaturePosition;
