import { useState } from 'react';

import LabelIcon from '@mui/icons-material/Label';
import { Grid, Button, Tooltip, CardActions } from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import { Tag } from 'src/@types/tags/tag';

import TagLabel from 'src/components/TagLabel';

import EditTagsDialog from './EditTagsDialog';

//------------------------------------------------------------

type TagSelectionPanelProps = {
  tags: Tag[];
  setTags: (tags: Tag[]) => void;
};

export default function TagSelectionPanel({ tags, setTags }: TagSelectionPanelProps) {
  const { t } = useLocales();
  const [isOpenTags, setIsOpenTags] = useState(false);
  const isMobile = useResponsive('down', 'sm');

  const handleClickOpenTags = () => {
    setIsOpenTags(true);
  };
  const handleCloseTags = () => {
    setIsOpenTags(false);
  };

  return (
    <>
      <Grid container spacing={0.5} sx={{ pt: 1, width: '100%' }}>
        {tags &&
          tags.length > 0 &&
          tags.map((t, index) => (
            <Grid item xs="auto" key={index}>
              <TagLabel color={t.color}>
                <Tooltip title={t.name}>
                  <span style={{ overflow: 'hidden', lineHeight: 'initial' }}>{t.name}</span>
                </Tooltip>
              </TagLabel>
            </Grid>
          ))}
      </Grid>

      <CardActions
        sx={{
          p: 0,
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'start',
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          startIcon={<LabelIcon />}
          onClick={handleClickOpenTags}
          id="p62d6sbs"
        >
          {t('documents.buttons.editTags')}
        </Button>
        {isOpenTags && (
          <EditTagsDialog
            tags={tags}
            setTags={setTags}
            open={isOpenTags}
            handleClose={handleCloseTags}
          />
        )}
      </CardActions>
    </>
  );
}
