import React, { useState, useEffect } from 'react';

import { Box, Button, IconButton } from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import { useSelector } from '../../../redux/store';
import HandwrittenSignatureDialog from './HandwrittenSignatureDialog';
import { clearSignatureState } from '../../../redux/slices/handwrittenSignature';
import SignaturePosition from '../../../@types/documents/enums/SignaturePosition';
import { INK_COLORS } from '../../../@types/documents/constants/SignatureConstants';
import drawSignature from '../../../utils/documents/handwritten-signature/drawSignature';

//-------------------------------------------------------

const HandwrittenSignature: React.FC = () => {
  const { t } = useLocales();
  const { signatureImg, dimensions, fullName, signatureCanvas } = useSelector(
    (state) => state.handwrittenSignature
  );
  const [isHandwrittenSignatureDialogOpen, setIsHandwrittenSignatureDialogOpen] = useState(false);
  const inkColor = INK_COLORS[0];
  const signaturePosition = SignaturePosition.BOTTOM_LEFT;

  const handleDeleteSignature = () => {
    clearSignatureState();
  };

  useEffect(() => {
    if (signatureCanvas) {
      drawSignature(fullName, signatureCanvas, signaturePosition, inkColor);
    }
  }, [signaturePosition]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
        {!signatureImg && (
          <Button
            id="jm91xk8p"
            fullWidth
            variant="contained"
            onClick={() => setIsHandwrittenSignatureDialogOpen(true)}
            sx={{ mt: 2 }}
          >
            {t('shared.buttons.addSignatureToSign')}
          </Button>
        )}
        {signatureImg && dimensions && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
              mt: 2,
            }}
          >
            <img
              style={{ height: `${dimensions.height * 0.2}px`, objectFit: 'scale-down' }}
              src={signatureImg}
              alt="signature"
            />

            <IconButton id="f1u1v1sn" onClick={handleDeleteSignature}>
              <img
                src="/icons/dlx-drive/actions/delete.svg"
                style={{ width: '20px', height: '20px' }}
                alt="Delete"
              />
            </IconButton>
          </Box>
        )}
      </Box>

      {isHandwrittenSignatureDialogOpen && (
        <HandwrittenSignatureDialog
          isHandwrittenSignatureDialogOpen={isHandwrittenSignatureDialogOpen}
          setIsHandwrittenSignatureDialogOpen={setIsHandwrittenSignatureDialogOpen}
          inkColor={inkColor}
          signaturePosition={signaturePosition}
        />
      )}
    </Box>
  );
};

export default HandwrittenSignature;
