export enum DocumentActionTypes {
  Created = 1,
  Signed = 2,
  Downloaded = 3,
  Opened = 4,
  RequstedSigning = 5,
  Edited = 6,
  Approved = 7,
  RepeatedInvite = 8,
  SigningDelegated = 9,
  Rejected = 10,
  ExtendedSignatureLevel = 11,
  Voided = 12,
  RequstedApprove = 13,
  CommentChanged = 14,
  RequestedAcknowledge = 15,
  Acknowledged = 16,
  AddedToSign = 17,
  AddedToApprove = 18,
  AddedToAcknowledge = 19,
  RemovedFromSign = 20,
  RemovedFromApprove = 21,
  RemovedFromAcknowledge = 22,
  AddedToMailGetter = 23,
  SentToMailGetter = 24,
  RemovedMailGetter = 25,
  MassApproved = 26,
  DocumentHistoryExport = 27,
  Moved = 28,
  Shared = 29,
  Delegated = 30,
  AccompanyingDocumentAdded = 31,
  AccompanyingDocumentRemoved = 32,
  AccompanyingDocumentMarkedAsSeen = 33,
  AccompanyingDocumentMarkedAsNotSeen = 34,
  SigningComment = 35,
  AccompanyingDocumentDownloaded = 36,
}
