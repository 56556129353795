import { format, getYear, getMonth } from 'date-fns';
import { useState, useEffect, ChangeEvent } from 'react';

import {
  Box,
  Card,
  Switch,
  useTheme,
  Typography,
  CardContent,
  FormControlLabel,
} from '@mui/material';

import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import { Analytics } from 'src/@types/analytics/types';
import dashboardB2BAnalyticsService from 'src/services/dasboardB2BAnalyticsService';

import DoughnutChart from 'src/sections/dashboard/b2b/dashboard-b2b-analytics/DoughnutChart';

import AuthSignLinearData from './AuthSignLinearData';
import DashboardSectionHeader from '../../DashboardSectionHeader';
import StatisticsDateRange, { DateValuesProps } from './StatisticsDateRange';

// ----------------------------------------------------------------------

const defaultData = {
  signatureSmartId: 0,
  signatureMobileId: 0,
  authenticationSmartId: 0,
  authenticationMobileId: 0,
  pdf: 0,
  adoc: 0,
  asice: 0,
  edoc: 0,
  bdoc: 0,
};

export default function DashboardB2BAnalytics() {
  const { t } = useLocales();
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const isMobile = useResponsive('down', 'sm');
  const [analyticsCounts, setAnalyticsCounts] = useState<Analytics>(defaultData);
  const [userAnalytics, setUserAnalytics] = useState<Analytics>(defaultData);
  const [adminAnalytics, setAdminAnalytics] = useState<Analytics>(defaultData);
  const { user } = useAuth();
  const currentMonth = getMonth(new Date()) + 1;
  const monthText = t(`dashboard.month.${currentMonth}`);
  const currentYear = getYear(new Date());
  const [checked, setChecked] = useState(false);

  const date = new Date();

  const defaultRangeDate = {
    startDate: `${format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd')}T00:00:00`,
    endDate: `${format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd')}T23:59:59`,
  };

  const [dateStatistics, setDateStatistics] = useState<DateValuesProps>({startDate: defaultRangeDate.startDate, endDate: defaultRangeDate.endDate});

  const totalSignature =
    analyticsCounts.pdf +
    analyticsCounts.adoc +
    analyticsCounts.asice +
    analyticsCounts.edoc +
    analyticsCounts.bdoc;

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [dateStatistics]);

  useEffect(() => {
    if (checked) {
      setAnalyticsCounts(adminAnalytics);
    } else {
      setAnalyticsCounts(userAnalytics);
    }
  }, [checked]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const fetchData = async () => {
    if (user !== null && user !== undefined) {
      const data = await dashboardB2BAnalyticsService.getSignatureAuthFilesCounts(
        user.email,
        dateStatistics
      );
      setAnalyticsCounts(data);
      setUserAnalytics(data);

      if (user.isAdmin) {
        const dataTen = await dashboardB2BAnalyticsService.getTenantStatistics(dateStatistics);
        setAdminAnalytics(dataTen);
        setChecked(true);
      }
    }
  };

  return (
    <Box>
      <DashboardSectionHeader localeText="dashboard.heresYourMainNumbers" />

      <Card>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 3, flexWrap: 'wrap', gap: 4 }}>

        
         {/* Card title */}
          <Typography variant="h6" color='primary' sx={{flex: 1}}>
            {t('dashboard.inMonthYearYouSigned', {
              month: `${monthText}`.toLowerCase(),
              year: `${currentYear}`,
            })}
          </Typography>
        
         
         <Box sx={{ display: "flex", flexWrap: 'wrap', gap: 2, justifyContent: 'space-between', flex: 3 }}>
            {/* Admin statistics data range */}
            <Box sx={{ flex: 3, display: user && user.isAdmin ? 'flex' : 'none', alignItems: 'center', ml: 'auto' }}>
              <StatisticsDateRange defaultRangeDate={defaultRangeDate} setDateStatistics={setDateStatistics} />
            </Box>

            {/* Admin general company switcher */}
            <Box sx={{ ml : 'auto', flex: 2 }}>
              <FormControlLabel
                value="end"
                control={
                  <Switch
                    checked={checked}
                    onChange={handleChange}
                    defaultChecked
                    color="primary"
                    sx={{ display: user?.isAdmin ? 'flex' : 'none', mb: "auto"}}
                    id="exv78lhi"
                  />
                }
                label={t('dashboard.generalTenantData')}
                labelPlacement="end"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  ml: 'auto',
                  '& .MuiFormControlLabel-label': {
                    color: isLight ? theme.palette.grey[600] : theme.palette.grey[300],
                    fontFamily: 'inherit',
                    whiteSpace: 'break-spaces',
                    display: user?.isAdmin ? 'flex' : 'none',
                  },
                }}
              />
            </Box>
         </Box>
        </Box>
 
        <CardContent
          sx={{
            p: 0,
            pr: isMobile ? 0 : 3,
            py: 1,
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          {/* dohnut chart */}
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="body1"
              component="div"
              color="text.secondary"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              {t('dashboard.typesOfDocuments')}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                marginBottom: 2,
                marginTop: 1,
              }}
            >
              <DoughnutChart analyticsCounts={analyticsCounts} />
            </Box>
          </Box>

          <Box sx={{ flex: 1 }}>

            {/* auth linear */}
            <Box sx={{display: 'flex'}}>
                < AuthSignLinearData 
                title={t('dashboard.authentication')}
                actionTypeMobileIdnum={analyticsCounts.authenticationMobileId} 
                actionTypeSmartIdnum={analyticsCounts.authenticationSmartId} 
                />
                {/* signatures linear */}
                < AuthSignLinearData 
                title={t('dashboard.signature')}
                actionTypeMobileIdnum={ analyticsCounts.signatureMobileId} 
                actionTypeSmartIdnum={analyticsCounts.signatureSmartId}  />
            </Box>
         
            {/* Total signatures */}
            <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', gap: 3, mt: 3, flex: 'wrap'}}>
              <Typography
                variant="subtitle1"
                component="div"
                color="text.secondary"
                sx={{ display: 'flex', ml: isMobile ? 3 : 4, alignItems: 'center' }}
              >
                {`${t('dashboard.totalSigned')}: ${totalSignature}`}
              </Typography>

            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
