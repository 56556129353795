import React, { useRef, useState, useEffect } from 'react';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Tooltip, useTheme, TextField, Autocomplete } from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import { setErrorList } from '../../../redux/slices/formBuilder';
import { getCustomListById } from '../../../services/formService';
import { CustomListProps } from '../edit-form/ElementSettingsDialog';
import validateIsRequired from '../../../@types/forms/utils/validateIsRequired';
import { ElementProps, ErrorsListProps, ElementValidationProps } from '../../../@types/forms/types';

//-------------------------------------------------------------

type CustomListElementProps = {
  el: ElementProps;
  scaledDimensions: {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    fontSize: number;
  };
  elementContentValue?: string[] | undefined;
  handleTextAlign: (alignEnum: number) => string;
  handleDropdownChange?: (value: string, el: ElementProps) => void;
  pageNumberScale: number;
};

const CustomListElement: React.FC<CustomListElementProps> = ({
  el,
  scaledDimensions,
  elementContentValue,
  handleTextAlign,
  handleDropdownChange,
  pageNumberScale,
}) => {
  const { t } = useLocales();
  const [customListOptions, setCustomListOptions] = useState<string[]>([]);
  const [value, setValue] = useState<string | undefined>(undefined);
  const [elementErrorsList, setElementErrorsList] = useState<ErrorsListProps[]>([]);
  const theme = useTheme();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const labelRef = useRef<HTMLSpanElement | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);

  const getListById = async () => {
    if (el.list) {
      try {
        const list = await getCustomListById(el.list.id);

        const { options } = list as CustomListProps;

        if (options) {
          setCustomListOptions(options);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const data: ElementValidationProps = { el, errorMessage: '', value, elementErrorsList };
    const newErrorList: ErrorsListProps[] = [];

    if (el.required) {
      const newError = validateIsRequired({
        ...data,
        errorMessage: t('forms.validationErrorMessage.fieldRequired'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    setElementErrorsList(newErrorList);
  }, [value]);

  useEffect(() => {
    if (elementErrorsList) {
      setErrorList({ elementID: el.id, errors: elementErrorsList });
    }
  }, [elementErrorsList]);

  useEffect(() => {
    if (elementContentValue) {
      setValue(elementContentValue[0]);
    }

    getListById();
  }, []);

  useEffect(() => {
    if (labelRef.current && inputRef.current) {
      const labelWidth = labelRef.current.offsetWidth;
      const inputWidth = inputRef.current.offsetWidth;
      setShowTooltip(labelWidth > inputWidth);
    }
  }, [el.label]);

  const handleFocus = () => {
    if(showTooltip){
      setTooltipOpen(true);
    }
  };
  
  const handleBlur = () => {
    setTooltipOpen(false);
  };

  return (
    <Tooltip
      title={el.label}
      open={tooltipOpen}
      onClose={handleBlur}
      placement="top-end"
      disableHoverListener
    >
      <Autocomplete
        ref={inputRef}
        popupIcon={<ExpandMoreRoundedIcon />}
        defaultValue={elementContentValue ? elementContentValue[0] : ''}
        options={customListOptions}
        disableClearable
        id={el.id}
        onChange={(event, newValue) => {
          handleDropdownChange && handleDropdownChange(newValue, el);
          setValue(newValue);
        }}
        sx={{
          backgroundColor: theme.palette.info.lighter,
          borderRadius: 1,
          width: '100%',
          '& input': {
            color: el.color,
            fontSize: `${scaledDimensions.fontSize}px`,
            textAlign: handleTextAlign(el.textAlignment),
            fontWeight: el.textStyle.includes('Bold') ? 'Bold' : 'Regular',
            fontStyle: el.textStyle.includes('Italic') ? 'italic' : 'normal',
            textDecoration: el.textDecoration === 1 ? 'underline' : '',
            fontFamily: `${el.font}`,
          },
        }}
        renderInput={(params) => (
          <TextField
            error={elementErrorsList.length > 0}
            helperText={elementErrorsList.length > 0 ? elementErrorsList[0].errorMessage : null}
            {...params}
            variant="standard"
            label={
              <span ref={labelRef}>
                {el.label}
              </span>
            }
            id="wcei5xzk"
            onFocus={handleFocus}
            onBlur={handleBlur}
            sx={{
              width: '100%',
            }} 
          />
        )}
      />
    </Tooltip>
  );
};

export default CustomListElement;
