import React, { useState, useEffect, ChangeEvent } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Card,
  Grid,
  Stack,
  Table,
  Button,
  Divider,
  TableRow,
  useTheme,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  CardHeader,
  IconButton,
  Pagination,
  Typography,
  InputAdornment,
  TableContainer,
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import { useSelector } from 'src/redux/store';
import { SortedTableCell } from 'src/@types/shared/sortedTableCell';
import { useConfirmationModalContext } from 'src/contexts/ConfirmationModalContext';

import Tip from 'src/components/Tip';
import Mobile from 'src/components/Mobile';
import Desktop from 'src/components/Desktop';
import Scrollbar from 'src/components/Scrollbar';
import SortedHeader from 'src/components/tables/SortedHeader';

import Item3dotDialog from 'src/sections/shared/item3dotDialog';

import EditEventTypeDialog from './EditEventTypeDialog';
import { setIsLoading } from '../../../redux/slices/calendar';
import LoadingScreen from '../../../components/LoadingScreen';
import AdminPanelBackButton from '../../admin/AdminPanelBackButton';
import { deleteEventType, getEventTypesList } from '../../../services/calendarService';
import { CalendarEventType, CalendarEventTypesFilter } from '../../../@types/calendar/types';

// ----------------------------------------------------------------------

const tableColumns: SortedTableCell[] = [
  {
    title: 'shared.fields.name',
  },
  {
    title: 'calendar.events.colorCode',
  },
  {
    title: '',
  },
];

const EventTypesList = () => {
  const { t } = useLocales();
  const { isLoading, eventTypesList, eventTypesListTotal } = useSelector((state) => state.calendar);
  const isMobile = useResponsive('down', 'sm');
  const [searchButtonPressed, setSearchButtonPressed] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState<CalendarEventType | null>(null);
  const [filter, setFilter] = useState({
    offset: 0,
    limit: isMobile ? 5 : 10,
    searchQuery: '',
  } as CalendarEventTypesFilter);

  const pageCount = () => Math.ceil(eventTypesListTotal / filter.limit);

  const handlePageChange = (event: any, value: number) => {
    setFilter({ ...filter, offset: value - 1 });
  };

  const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, searchQuery: event.target.value, offset: 0 });
  };

  function reloadEventTypesList() {
    getEventTypesList(filter);
  }

  useEffect(() => {
    reloadEventTypesList();
  }, [filter]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const TableHeader = () => (
    <>
      <Mobile>
        <Grid container py={2} direction="column" spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <CardHeader title={t('calendar.events.calendarEvents')} sx={{ p: 0 }} />

              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setSearchButtonPressed(!searchButtonPressed)}
                id="x7xoakba"
              >
                <SearchIcon />
              </Button>
            </Stack>
          </Grid>

          {searchButtonPressed && (
            <Grid item xs={12}>
              <TextField
                focused
                fullWidth
                size="small"
                label={t('shared.fields.search')}
                value={filter.searchQuery}
                onChange={handleSearchQueryChange}
                id="e37umq5b"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              id="4w8weg0v"
              onClick={() => setIsDialogOpen(true)}
            >
              {t('shared.buttons.create')}
            </Button>
          </Grid>
        </Grid>
      </Mobile>

      <Desktop>
        <Stack px={1} py={2} direction="row" justifyContent="space-between">
          <CardHeader title={t('calendar.events.calendarEvents')} sx={{ p: 0 }} />

          <Stack spacing={2} direction="row">
            <TextField
              size="small"
              sx={{ width: '200px' }}
              label={t('shared.fields.search')}
              value={filter.searchQuery}
              onChange={handleSearchQueryChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              id="65wq3ldh"
            />
            <Button variant="contained" onClick={() => setIsDialogOpen(true)} id="c93nppcy">
              {t('shared.buttons.create')}
            </Button>
          </Stack>
        </Stack>
      </Desktop>
    </>
  );

  return (
    <Box>
      <AdminPanelBackButton />

      {TableHeader()}

      <Mobile>
        <Scrollbar>
          <SortedHeader
            columns={tableColumns.filter((c) => c.title !== '')}
            handleSortingChange={() => {}}
            isDownload={false}
            isCardView
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              opacity: 1,
            }}
          >
            {eventTypesListTotal > 0 &&
              eventTypesList.map((item) => (
                <EventTypeItem
                  key={item.id}
                  eventType={item}
                  setSelectedEventType={setSelectedEventType}
                  setIsDialogOpen={setIsDialogOpen}
                />
              ))}
          </Box>
        </Scrollbar>
      </Mobile>

      <Desktop>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 300 }}>
            <Table>
              <TableHead>
                <SortedHeader
                  columns={tableColumns}
                  handleSortingChange={() => {}}
                  isDownload={false}
                  isCardView={false}
                />
              </TableHead>
              <TableBody>
                {eventTypesListTotal > 0 &&
                  eventTypesList.map((item) => (
                    <EventTypeItem
                      key={item.id}
                      eventType={item}
                      setSelectedEventType={setSelectedEventType}
                      setIsDialogOpen={setIsDialogOpen}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Desktop>

      <Divider />

      <Box sx={{ p: 2 }}>
        {eventTypesListTotal > 0 && (
          <Pagination
            sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'end' } }}
            count={pageCount()}
            onChange={handlePageChange}
            page={filter.offset + 1}
          />
        )}
      </Box>

      <EditEventTypeDialog
        eventType={selectedEventType}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        setSelectedEventType={setSelectedEventType}
      />

      {isLoading && <LoadingScreen />}
    </Box>
  );
};

type EventTypeItemProps = {
  eventType: CalendarEventType;
  setSelectedEventType: (eventType: CalendarEventType) => void;
  setIsDialogOpen: (isDialogOpen: boolean) => void;
};

const EventTypeItem: React.FC<EventTypeItemProps> = ({
  eventType,
  setSelectedEventType,
  setIsDialogOpen,
}) => {
  const { t, currentLang } = useLocales();
  const theme = useTheme();
  const { isLoading } = useSelector((state) => state.calendar);
  const [name, setName] = useState({});
  const modalContext = useConfirmationModalContext();

  const rowStyle = {
    boxShadow: '2px 3px 4px #CCCCCC',
    cursor: 'pointer',
  };

  const handleEventTypeClick = () => {
    setSelectedEventType(eventType);

    setIsDialogOpen(true);
  };

  const handleRemove = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const result = await modalContext.showConfirmation(
      t('calendar.events.confirmation'),
      t('calendar.events.confirmationRemovePermanently', {
        eventTypeName: name[`${currentLang.value}`],
      })
    );

    if (result) {
      try {
        await deleteEventType(eventType.id);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    let newNames = JSON.parse(eventType.name);

    setName(newNames);
  }, [eventType.name]);

  return (
    <>
      <Mobile>
        <Card
          sx={{
            gap: 0.5,
            pl: 2,
            py: 3,
            border: '1px solid #fff',
            display: 'flex',
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Box sx={{ pb: 1 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  flex: 1,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'wrap',
                }}
              >
                {name[`${currentLang.value}`]}
              </Typography>
            </Box>

            <Box sx={{ color: theme.palette.text.secondary, pb: 1, display: 'flex' }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                {t('calendar.events.colorCode')}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  gap: 2,
                  px: 2,
                  minWidth: '100px',
                }}
              >
                <Box
                  sx={{
                    width: '30px',
                    height: '30px',
                    border: `1px solid ${eventType.colorCode}`,
                    borderRadius: '50%',
                  }}
                >
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      backgroundColor: `${eventType.colorCode}`,
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    width: '30px',
                    height: '30px',
                    border: `1px solid ${eventType.colorCode}`,
                    borderRadius: '50%',
                  }}
                >
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      opacity: 0.1,
                      background: `repeating-linear-gradient(45deg, ${eventType.colorCode}, ${eventType.colorCode} 15px, #fff 15px, #fff 20px)`,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Item3dotDialog
              handleEdit={handleEventTypeClick}
              handleRemove={handleRemove}
              isLoading={isLoading}
            />
          </Box>
        </Card>
      </Mobile>

      <Desktop>
        <TableRow sx={rowStyle} className="activeHover">
          <TableCell
            onClick={handleEventTypeClick}
            sx={{
              maxWidth: '150px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {name[`${currentLang.value}`]}
          </TableCell>

          <TableCell onClick={handleEventTypeClick}>
            <Box
              sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 2, px: 2 }}
            >
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '150px',
                  height: '30px',
                  border: `1px solid ${eventType.colorCode}`,
                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 1,
                    backgroundColor: `${eventType.colorCode}`,
                  }}
                />
              </Box>

              <Box
                sx={{
                  width: '100%',
                  maxWidth: '150px',
                  height: '30px',
                  border: `1px solid ${eventType.colorCode}`,
                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 1,
                    opacity: 0.1,
                    background: `repeating-linear-gradient(45deg, ${eventType.colorCode}, ${eventType.colorCode} 15px, #fff 15px, #fff 20px)`,
                  }}
                />
              </Box>
            </Box>
          </TableCell>

          <TableCell
            onClick={handleEventTypeClick}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Tip titleLocale="shared.buttons.delete">
              <IconButton size="small" onClick={handleRemove} id="wxpasfp3">
                <Box sx={{ height: '20px', width: '20px' }}>
                  <img src="/icons/icon_trashbin.svg" width={20} height={20} alt="delete" />
                </Box>
              </IconButton>
            </Tip>
          </TableCell>
        </TableRow>
      </Desktop>
    </>
  );
};

export default EventTypesList;
