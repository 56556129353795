import * as console from 'node:console';
import SignaturePad from 'react-signature-pad-wrapper';
import React, { useRef, useState, useEffect, ChangeEvent } from 'react';

import {
  Box,
  Button,
  Dialog,
  useTheme,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
import useResponsive from '../../../hooks/useResponsive';
import SignaturePosition from '../../../@types/documents/enums/SignaturePosition';
import drawSignature from '../../../utils/documents/handwritten-signature/drawSignature';
import {
  setSignatureCanvas,
  setSignatureFullName,
} from '../../../redux/slices/handwrittenSignature';

//-------------------------------------------------------

type HandwrittenSignatureDialogProps = {
  isHandwrittenSignatureDialogOpen: boolean;
  setIsHandwrittenSignatureDialogOpen: (isHandwrittenSignatureDialogOpen: boolean) => void;
  inkColor: string;
  signaturePosition: SignaturePosition;
};

const HandwrittenSignatureDialog: React.FC<HandwrittenSignatureDialogProps> = ({
  isHandwrittenSignatureDialogOpen,
  setIsHandwrittenSignatureDialogOpen,
  inkColor,
  signaturePosition,
}) => {
  const { t } = useLocales();
  const [addedEventListener, setAddedEventListener] = useState<boolean>(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState<boolean>(true);
  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');

  const canvasRef = useRef<any>(null);
  const canvasObj = canvasRef.current;

  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');
  const { user } = useAuth();

  const handleDialogClose = () => {
    setAddedEventListener(false);
    setIsHandwrittenSignatureDialogOpen(false);
  };

  const handleClearSignature = () => {
    canvasObj.clear();
    setIsCanvasEmpty(true);
  };

  const handleEndStroke = () => {
    setIsCanvasEmpty(canvasObj.isEmpty());
  };

  const handleContinue = () => {
    try {
      const signatureCanvas: HTMLCanvasElement = canvasObj.signaturePad.canvas;
      setSignatureCanvas(signatureCanvas);

      const fullName = `${name} ${surname}`;
      setSignatureFullName(fullName);

      drawSignature(fullName, signatureCanvas, signaturePosition, inkColor);
    } catch (error) {
      console.error(error);
    } finally {
      handleDialogClose();
    }
  };

  useEffect(() => {
    if (!addedEventListener && canvasObj) {
      canvasObj.signaturePad.addEventListener('endStroke', handleEndStroke);

      setAddedEventListener(true);
    }
  }, [name, surname]);

  useEffect(() => {
    if (user) {
      if (user.firstName) {
        setName(user.firstName);
      }

      if (user.lastName) {
        setSurname(user.lastName);
      }
    }
  }, [user]);

  return (
    <Dialog
      open={isHandwrittenSignatureDialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{t('forms.addSignatureToSign')}</DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>
          {t('documents.writeDrawClickContinue')}
        </DialogContentText>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: isMobile ? 2 : 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: isMobile ? 1 : 3,
            }}
          >
            <TextField
              fullWidth
              size="small"
              label={t('shared.fields.firstName')}
              value={name}
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              autoComplete="off"
              id="9i2feut6"
            />

            <TextField
              fullWidth
              size="small"
              label={t('shared.fields.lastName')}
              value={surname}
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSurname(event.target.value);
              }}
              autoComplete="off"
              id="xxceh4gk"
            />
          </Box>

          <Box sx={{ border: `1px solid ${theme.palette.grey[300]}` }} id="dvabn4kt">
            <SignaturePad
              ref={canvasRef}
              options={{
                penColor: inkColor,
                minWidth: isMobile ? 1 : 2,
                maxWidth: isMobile ? 1 : 2,
              }}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: 3,
          pb: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            gap: 3,
          }}
        >
          <Button variant="outlined" onClick={handleDialogClose} id="mz5nnxqa">
            {t('shared.buttons.cancel')}
          </Button>

          <Button
            disabled={isCanvasEmpty}
            variant="text"
            onClick={handleClearSignature}
            id="3anq0rc3"
          >
            {t('shared.buttons.clear')}
          </Button>
        </Box>

        <Button
          disabled={name.trim().length === 0 || surname.trim().length === 0 || isCanvasEmpty}
          variant="contained"
          onClick={handleContinue}
          id="b2pe340j"
        >
          {t('shared.buttons.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HandwrittenSignatureDialog;
