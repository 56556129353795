import fileDownload from 'js-file-download';
import React, { useState, useEffect } from 'react';

import { Divider } from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import { useSelector } from '../../../redux/store';
import MenuPopover from '../../../components/MenuPopover';
import useNotifications from '../../../hooks/useNotifications';
import DLXDriveFolderActionsMenuItem from './DLXDriveFolderActionsMenuItem';
import DLXDriveSingleShareDialog from '../dialogs/DLXDriveSingleShareDialog';
import { FolderActions } from '../../../@types/dlx-drive/enums/FolderActions';
import DLXDriveSingleDeleteDialog from '../dialogs/DLXDriveSingleDeleteDialog';
import DLXDriveMoveFolderDialog from '../dialogs/folders/DLXDriveMoveFolderDialog';
import DLXDriveRenameFolderDialog from '../dialogs/folders/DLXDriveRenameFolderDialog';
import DLXDriveManageAccessDialog from '../dialogs/folders/DLXDriveManageAccessDialog';
import { setNavigationState, addStopLoadingState } from '../../../redux/slices/dlxDrive';
import DLXDriveFormTemplatesDialog from '../dialogs/folders/DLXDriveFormTemplatesDialog';
import { DLXDriveFolderType, DLXDriveBulkDownloadModelType } from '../../../@types/dlx-drive/types';
import DLXDriveChangeFolderColorAndIconDialog from '../dialogs/folders/DLXDriveChangeFolderColorAndIconDialog';
import {
  bulkDownload,
  pinToNavigation,
  getFolderNamePrefix,
} from '../../../services/dlxDriveService';

// ----------------------------------------------------------------

type DLXDriveFolderActionsMenuProps = {
  isMenuPopoverOpen: boolean;
  setIsMenuPopoverOpen: (value: boolean) => void;
  anchorEl: React.RefObject<HTMLElement>;
  folderId?: number;
  folderColor: string;
  folderIcon: string;
};

const DLXDriveFolderActionsMenu: React.FC<DLXDriveFolderActionsMenuProps> = ({
  isMenuPopoverOpen,
  setIsMenuPopoverOpen,
  anchorEl,
  folderId,
  folderColor,
  folderIcon,
}) => {
  const { t } = useLocales();
  const { children, selectedFolder, breadcrumbs, pinnedFolderIds } = useSelector(
    (state) => state.dlxDrive
  );
  const [isRenameFolderDialogOpen, setIsRenameFolderDialogOpen] = useState<boolean>(false);
  const [isMoveFolderDialogOpen, setIsMoveFolderDialogOpen] = useState<boolean>(false);
  const [isFormTemplatesDialogOpen, setIsFormTemplatesDialogOpen] = useState<boolean>(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isManageAccessDialogOpen, setIsManageAccessDialogOpen] = useState<boolean>(false);

  const [isChangeFolderColorAndIconDialogOpen, setIsChangeFolderColorAndIconDialogOpen] =
    useState<boolean>(false);
  const [folderName, setFolderName] = useState<string | null>(null);
  const [folderPrefix, setFolderPrefix] = useState<string | undefined>(undefined);
  const [folderActions, setFolderActions] = useState<FolderActions[]>([]);
  const [isRootFolder, setIsRootFolder] = useState<boolean>(false);
  const { enqueueSuccess, enqueueError } = useNotifications();

  const handleClose = () => {
    setIsMenuPopoverOpen(false);
  };

  const handleFolderMoveClick = () => {
    setIsMoveFolderDialogOpen(true);
    handleClose();
  };

  const handleManageAccessClick = () => {
    setIsManageAccessDialogOpen(true);
    handleClose();
  };

  const handleFolderRenameClick = () => {
    setIsRenameFolderDialogOpen(true);
    handleClose();
  };

  const handleFormTemplatesClick = () => {
    setIsFormTemplatesDialogOpen(true);
    handleClose();
  };

  const handleShareClick = () => {
    setIsShareDialogOpen(true);
    handleClose();
  };

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
    handleClose();
  };

  const handlePinToNavigationClick = async () => {
    let pinFolderName: string = '';
    let pinFolderId: number = -1;

    if (folderId) {
      if (folderName) {
        pinFolderName = folderName;
      }

      pinFolderId = folderId;
    } else {
      pinFolderName = selectedFolder.name;
      pinFolderId = selectedFolder.id;
    }

    try {
      await pinToNavigation(pinFolderId);

      enqueueSuccess(
        t('dlxDrive.notifications.folderSuccessfullyPinned', {
          itemName: pinFolderName,
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setNavigationState();
      handleClose();
    }
  };

  const handleFolderDownloadClick = async () => {
    let model: DLXDriveBulkDownloadModelType = {};

    if (folderId) {
      model = { folderIds: [folderId] };
    } else {
      model = { folderIds: [selectedFolder.id] };
    }

    model.documentIds = [];

    try {
      const file = await bulkDownload(model);

      fileDownload(file.file, file.fileName, file.mime);
      enqueueSuccess('dlxDrive.notifications.downloadSuccess');
    } catch (error) {
      enqueueError('dlxDrive.errors.downloadError');
    } finally {
      addStopLoadingState();
    }
  };

  const handleColorIconChangeClick = async () => {
    setIsChangeFolderColorAndIconDialogOpen(true);
    handleClose();
  };

  useEffect(() => {
    if (isMenuPopoverOpen) {
      if (folderId) {
        const folder = getFolderNamePrefix(folderId, children) as DLXDriveFolderType[];

        setFolderActions(folder[0].actions);
        setFolderName(folder[0].name);
        setFolderPrefix(folder[0].prefix);
      } else {
        setFolderActions(selectedFolder.actions);
        setFolderName(selectedFolder.name);
        setFolderPrefix(selectedFolder.prefix);
      }

      if (
        folderId === undefined &&
        breadcrumbs.length === 1 &&
        selectedFolder.id === breadcrumbs[0].id
      ) {
        setIsRootFolder(true);
      } else {
        setIsRootFolder(false);
      }
    }
  }, [isMenuPopoverOpen]);

  return (
    <>
      <MenuPopover
        open={isMenuPopoverOpen}
        onClose={handleClose}
        anchorEl={anchorEl.current}
        sx={{
          ml: '-1px',
          width: '242px',
        }}
      >
        <DLXDriveFolderActionsMenuItem
          disabled={folderActions ? !folderActions.includes(FolderActions.ManageAccess) : true}
          src="/icons/dlx-drive/actions/access.svg"
          name={t('dlxDrive.folderSettings.manageAccess')}
          onClick={handleManageAccessClick}
          id="CZETZ8"
        />

        <DLXDriveFolderActionsMenuItem
          disabled={
            folderActions ? !folderActions.includes(FolderActions.FolderMove) || isRootFolder : true
          }
          src="/icons/dlx-drive/actions/move.svg"
          name={t('dlxDrive.folderSettings.moveTo')}
          onClick={handleFolderMoveClick}
          id="BGVSRF"
        />

        <DLXDriveFolderActionsMenuItem
          disabled={folderActions ? !folderActions.includes(FolderActions.Read) : true}
          src="/icons/dlx-drive/actions/pin.svg"
          name={
            pinnedFolderIds.includes(folderId || selectedFolder.id)
              ? t('dlxDrive.folderSettings.unpinFromNavigation')
              : t('dlxDrive.folderSettings.pinToNavigation')
          }
          onClick={handlePinToNavigationClick}
          id="uO7xvY"
        />

        <DLXDriveFolderActionsMenuItem
          disabled={
            folderActions
              ? !folderActions.includes(FolderActions.ManageFormTemplates) || isRootFolder
              : true
          }
          src="/icons/dlx-drive/actions/template.svg"
          name={t('dlxDrive.folderSettings.formTemplates')}
          onClick={handleFormTemplatesClick}
          id="NoOKkF"
        />

        <Divider />

        <DLXDriveFolderActionsMenuItem
          disabled={folderActions ? !folderActions.includes(FolderActions.FolderEdit) : true}
          src="/icons/dlx-drive/actions/rename.svg"
          name={t('dlxDrive.folderSettings.rename')}
          onClick={handleFolderRenameClick}
          id="u4yd2A"
        />

        <DLXDriveFolderActionsMenuItem
          disabled={folderActions ? !folderActions.includes(FolderActions.Read) : true}
          src="/icons/dlx-drive/actions/icon.svg"
          name={t('dlxDrive.folderSettings.colorAndIcon')}
          onClick={handleColorIconChangeClick}
          id="x2jsucmi"
        />

        <Divider />

        <DLXDriveFolderActionsMenuItem
          disabled={folderActions ? !folderActions.includes(FolderActions.FolderDownload) : true}
          src="/icons/dlx-drive/actions/download.svg"
          name={t('dlxDrive.folderSettings.download')}
          onClick={handleFolderDownloadClick}
          id="03eCow"
        />

        <DLXDriveFolderActionsMenuItem
          disabled={folderActions ? !folderActions.includes(FolderActions.FolderShare) : true}
          src="/icons/dlx-drive/actions/share.svg"
          name={t('dlxDrive.folderSettings.share')}
          onClick={handleShareClick}
          id="9DdFZx"
        />

        <DLXDriveFolderActionsMenuItem
          disabled={
            folderActions ? !folderActions.includes(FolderActions.Delete) || isRootFolder : true
          }
          src="/icons/dlx-drive/actions/delete.svg"
          name={t('dlxDrive.folderSettings.delete')}
          onClick={handleDeleteClick}
          id="qkQu9Q"
        />
      </MenuPopover>

      <DLXDriveMoveFolderDialog
        folderId={folderId || selectedFolder.id}
        folderName={folderName || selectedFolder.name}
        isMoveFolderDialogOpen={isMoveFolderDialogOpen}
        setIsMoveFolderDialogOpen={setIsMoveFolderDialogOpen}
      />

      <DLXDriveRenameFolderDialog
        folderId={folderId || selectedFolder.id}
        folderName={folderName || selectedFolder.name}
        folderPrefix={folderPrefix}
        isRenameFolderDialogOpen={isRenameFolderDialogOpen}
        setIsRenameFolderDialogOpen={setIsRenameFolderDialogOpen}
      />

      <DLXDriveFormTemplatesDialog
        folderId={folderId || selectedFolder.id}
        folderName={folderName || selectedFolder.name}
        isFormTemplatesDialogOpen={isFormTemplatesDialogOpen}
        setIsFormTemplatesDialogOpen={setIsFormTemplatesDialogOpen}
      />

      <DLXDriveSingleShareDialog
        itemId={folderId || selectedFolder.id}
        itemName={folderName || selectedFolder.name}
        isFolder
        isShareDialogOpen={isShareDialogOpen}
        setIsShareDialogOpen={setIsShareDialogOpen}
      />

      <DLXDriveSingleDeleteDialog
        itemId={folderId || selectedFolder.id}
        itemName={folderName || selectedFolder.name}
        isFolder
        isDeleteDialogOpen={isDeleteDialogOpen}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
      />

      <DLXDriveManageAccessDialog
        folderId={folderId || selectedFolder.id}
        folderName={folderName || selectedFolder.name}
        isManageAccessDialogOpen={isManageAccessDialogOpen}
        setIsManageAccessDialogOpen={setIsManageAccessDialogOpen}
      />

      <DLXDriveChangeFolderColorAndIconDialog
        folderId={folderId || selectedFolder.id}
        folderName={folderName || selectedFolder.name}
        folderColor={folderColor}
        folderIcon={folderIcon}
        isChangeFolderColorAndIconDialogOpen={isChangeFolderColorAndIconDialogOpen}
        setIsChangeFolderColorAndIconDialogOpen={setIsChangeFolderColorAndIconDialogOpen}
      />
    </>
  );
};

export default DLXDriveFolderActionsMenu;
