import cropCanvas from './cropCanvas';
import combineCanvas from './combineCanvas';
import drawTextOnCanvas from './drawTextOnCanvas';
import SignaturePosition from '../../../@types/documents/enums/SignaturePosition';
import {
  setSignatureImg,
  setSignatureDimensions,
} from '../../../redux/slices/handwrittenSignature';

const drawSignature = (
  fullName: string,
  signatureCanvas: HTMLCanvasElement,
  signaturePosition: SignaturePosition,
  inkColor: string
) => {
  const nameCanvas: HTMLCanvasElement = drawTextOnCanvas(fullName, inkColor);

  const trimmedNameCanvas = cropCanvas(nameCanvas);
  const trimmedSignatureCanvas = cropCanvas(signatureCanvas);

  const combinedCanvas = combineCanvas(
    trimmedNameCanvas,
    trimmedSignatureCanvas,
    signaturePosition
  );

  const { width, height } = combinedCanvas;
  setSignatureDimensions({ width, height });

  const signatureImage = combinedCanvas.toDataURL('image/png');
  setSignatureImg(signatureImage);
};

export default drawSignature;
