// NEW FORM BUILDER COMPONENT

import { debounce } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Card,
  Grid,
  Stack,
  Table,
  Button,
  Divider,
  Tooltip,
  TableRow,
  useTheme,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  IconButton,
  Pagination,
  Typography,
  InputAdornment,
  TableContainer,
} from '@mui/material';

import { PATH_ADMIN } from 'src/routes/paths';

import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import { fDateTime } from 'src/utils/formatTime';

import { Tag } from 'src/@types/tags/tag';
import { useSelector } from 'src/redux/store';
import { getPagedTags } from 'src/redux/slices/tag';
import { TagsFilter } from 'src/@types/tags/tagsFilter';
import { SortedTableCell } from 'src/@types/shared/sortedTableCell';
import { getForms, removeFormTemplate } from 'src/redux/slices/formBuilder';
import { DocumentModuleTypes } from 'src/@types/shared/enums/documentModuleTypes';
import { useConfirmationModalContext } from 'src/contexts/ConfirmationModalContext';
import { FormTemplatesFilter } from 'src/@types/form-templates/formTemplatesFilter';

import Tip from 'src/components/Tip';
import Mobile from 'src/components/Mobile';
import Desktop from 'src/components/Desktop';
import TagLabel from 'src/components/TagLabel';
import Scrollbar from 'src/components/Scrollbar';
import SortedHeader from 'src/components/tables/SortedHeader';

import Item3dotDialog from 'src/sections/shared/item3dotDialog';
import DuplicateFormDialog from 'src/sections/forms/duplicate-form/DuplicateFormDialog';

import { FormProps } from '../../@types/forms/types';

// ----------------------------------------------------------------------

enum FormTemplatesSortingColumns {
  Name = 0,
  Date = 1,
}

const tableColumns: SortedTableCell[] = [
  {
    sortingKey: FormTemplatesSortingColumns.Name,
    title: 'shared.fields.name',
  },
  {
    sortingKey: FormTemplatesSortingColumns.Date,
    title: 'formTemplates.created',
  },
  {
    title: 'formTemplates.module',
  },
  {
    title: 'formTemplates.tags',
  },
  {
    title: '',
  },
];

type FormTemplatesFilterState = {
  page: number;
  pageSize: number;
  searchQuery: string;
  sortingColumn: FormTemplatesSortingColumns | null;
  sortDescending: boolean | null;
};

const FormTemplatesList = () => {
  const { t } = useLocales();
  const { forms } = useSelector((state) => state.formBuilder);
  const debounceReloadFormTemplates = useCallback(debounce(reloadFormTemplates, 500), []);
  const isMobile = useResponsive('down', 'sm');
  const [searchButtonPressed, setSearchButtonPressed] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
    pageSize: isMobile ? 5 : 10,
    searchQuery: '',
    sortingColumn: null,
    sortDescending: null,
  } as FormTemplatesFilterState);

  function pageCount() {
    return Math.ceil((forms?.total || 0) / filter.pageSize);
  }

  const handlePageChange = (event: any, value: number) => {
    setFilter({ ...filter, page: value });
  };

  const handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, searchQuery: event.target.value, page: 1 });
  };

  const handleSortingChange = (
    column: FormTemplatesSortingColumns | null,
    sortDescending: boolean
  ) => {
    setFilter({ ...filter, sortingColumn: column, sortDescending, page: 1 });
  };

  function reloadFormTemplates(filterState: FormTemplatesFilterState) {
    const formtemplatesFilter = new FormTemplatesFilter();
    formtemplatesFilter.limit = filterState.pageSize;
    formtemplatesFilter.offset = filterState.page - 1;
    formtemplatesFilter.searchQuery =
      filterState.searchQuery !== '' ? filterState.searchQuery : null;
    formtemplatesFilter.sortingColumn = filterState.sortingColumn;
    formtemplatesFilter.sortDescending =
      filterState.sortingColumn !== null ? filterState.sortDescending : null;
    getForms(formtemplatesFilter);
  }

  useEffect(() => {
    getPagedTags(new TagsFilter());
  }, []);

  useEffect(() => {
    debounceReloadFormTemplates(filter);
  }, [filter]);

  const onRemove = () => {
    reloadFormTemplates(filter);
  };

  const handleReload = () => {
    reloadFormTemplates(filter);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function TableHeader() {
    return (
      <>
        <Mobile>
          <Grid container py={2} direction="column" spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="right">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setSearchButtonPressed(!searchButtonPressed)}
                  id="x7xoakba"
                >
                  <SearchIcon />
                </Button>
              </Stack>
            </Grid>

            {searchButtonPressed && (
              <Grid item xs={12}>
                <TextField
                  focused
                  fullWidth
                  size="small"
                  label={t('shared.fields.search')}
                  value={filter.searchQuery}
                  onChange={handleSearchQueryChange}
                  id="e37umq5b"
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                component={Link}
                to={PATH_ADMIN.formsCreate}
                id="4w8weg0v"
              >
                {t('shared.buttons.create')}
              </Button>
            </Grid>
          </Grid>
        </Mobile>
        <Desktop>
          <Stack px={1} py={2} direction="row" justifyContent="right">
            <Stack spacing={2} direction="row">
              <TextField
                size="small"
                sx={{ width: '200px' }}
                label={t('shared.fields.search')}
                value={filter.searchQuery}
                onChange={handleSearchQueryChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                id="65wq3ldh"
              />
              <Button
                variant="contained"
                component={Link}
                to={PATH_ADMIN.formsCreate}
                id="c93nppcy"
              >
                {t('shared.buttons.create')}
              </Button>
            </Stack>
          </Stack>
        </Desktop>
      </>
    );
  }

  return (
    <Box>
      {TableHeader()}

      <Mobile>
        <Scrollbar>
          <SortedHeader
            columns={tableColumns.filter((c) => c.sortingKey !== undefined)}
            handleSortingChange={handleSortingChange}
            isDownload={false}
            isCardView
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              opacity: 1,
            }}
          >
            {forms?.items?.map((item) => (
              <FormTemplateItem
                key={item.id}
                formTemplate={item}
                onRemove={onRemove}
                handleReload={handleReload}
              />
            ))}
          </Box>
        </Scrollbar>
      </Mobile>

      <Desktop>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 300 }}>
            <Table>
              <TableHead>
                <SortedHeader
                  columns={tableColumns}
                  handleSortingChange={handleSortingChange}
                  isDownload={false}
                  isCardView={false}
                />
              </TableHead>
              <TableBody>
                {forms?.items?.map((item) => (
                  <FormTemplateItem
                    key={item.id}
                    formTemplate={item}
                    onRemove={onRemove}
                    handleReload={handleReload}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Desktop>

      <Divider />

      <Box sx={{ p: 2 }}>
        <Pagination
          sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'end' } }}
          count={pageCount()}
          onChange={handlePageChange}
          page={filter.page}
        />
      </Box>
    </Box>
  );
};

type FormTemplateItemProps = {
  formTemplate: FormProps;
  onRemove: () => void;
  handleReload: () => void;
};

function FormTemplateItem({ formTemplate, onRemove, handleReload }: FormTemplateItemProps) {
  const { t } = useLocales();
  const theme = useTheme();
  const { isLoading } = useSelector((state) => state.formBuilder);
  const navigate = useNavigate();
  const modalContext = useConfirmationModalContext();
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);
  const isLight = theme.palette.mode === 'light';

  function onFormTemplatesSelected() {
    navigate(PATH_ADMIN.formsEdit(formTemplate.id));
  }

  const rowStyle = {
    boxShadow: isLight ? '2px 3px 4px #CCCCCC' : 'none',
    cursor: 'pointer',
  };

  const handleRemove = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const result = await modalContext.showConfirmation(
      t('components.confirmationModal.confirmation'),
      t('formTemplates.areYouSureWantToRemovePermanently')
    );

    if (result) {
      await removeFormTemplate(formTemplate.id);
      onRemove();
    }
  };

  const handleDuplicate = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsDuplicateDialogOpen(true);
  };

  const handleModalClose = () => {
    handleReload();
  };

  const moduleTitle = () => {
    if (formTemplate.formModuleType === DocumentModuleTypes.HR) {
      return t('formTemplates.modules.hr');
    }
    if (formTemplate.formModuleType === DocumentModuleTypes.Accounting) {
      return t('formTemplates.modules.accounting');
    }
    return null;
  };

  const tagsTableCell = (tagsList: Tag[]) => (
    <Grid container spacing={0.5}>
      {tagsList.map((t, index) => (
        <Grid item xs="auto" key={index}>
          <TagLabel color={t.color}>
            <Tooltip title={t.name}>
              <span style={{ overflow: 'hidden', lineHeight: 'initial' }}>{t.name}</span>
            </Tooltip>
          </TagLabel>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      <Mobile>
        <Card
          sx={{
            gap: 0.5,
            pl: 2,
            py: 2,
            border: '1px solid #fff',
            display: 'flex',
          }}
        >
          <Box sx={{ flex: 1 }} onClick={onFormTemplatesSelected} id="x8js1aeu">
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  flex: 1,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'wrap',
                }}
              >
                {formTemplate.name}
              </Typography>
            </Box>

            <Box sx={{ color: theme.palette.text.secondary, display: 'flex' }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                {t('formTemplates.created')}:{' '}
              </Typography>
              <Typography variant="body2" color={theme.palette.text.primary}>
                {fDateTime(formTemplate.created)}
              </Typography>
            </Box>

            <Box sx={{ color: theme.palette.text.secondary, display: 'flex' }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                {t('formTemplates.module')}:{' '}
              </Typography>
              <Typography variant="body2" color={theme.palette.text.primary}>
                {moduleTitle()}
              </Typography>

              <Box sx={{ ml: 3 }}>
                {formTemplate.tags !== undefined && tagsTableCell(formTemplate.tags)}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Item3dotDialog
              handleRemove={handleRemove}
              handleDuplicate={handleDuplicate}
              isLoading={isLoading}
            />
          </Box>
        </Card>
      </Mobile>

      <Desktop>
        <TableRow sx={rowStyle} className="activeHover">
          <TableCell
            onClick={onFormTemplatesSelected}
            sx={{
              maxWidth: '150px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {formTemplate.name}
          </TableCell>
          <TableCell onClick={onFormTemplatesSelected}>{fDateTime(formTemplate.created)}</TableCell>
          <TableCell onClick={onFormTemplatesSelected}>{moduleTitle()}</TableCell>
          <TableCell onClick={onFormTemplatesSelected}>
            {formTemplate.tags !== undefined && tagsTableCell(formTemplate.tags)}
          </TableCell>
          <TableCell sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* Form Duplicate temporary commented for [R-2024-06-19] */}
            <Tip titleLocale="formTemplates.duplicate">
              <IconButton size="small" onClick={handleDuplicate} id="pfmdgkky">
                <Box sx={{ height: '20px', width: '20px' }}>
                  <img src="/icons/duplicate_form.svg" width={20} height={20} alt="duplicate" />
                </Box>
              </IconButton>
            </Tip>

            <Tip titleLocale="shared.buttons.delete">
              <IconButton size="small" onClick={handleRemove} id="wxpasfp3">
                <Box sx={{ height: '20px', width: '20px' }}>
                  <img src="/icons/icon_trashbin.svg" width={20} height={20} alt="delete" />
                </Box>
              </IconButton>
            </Tip>
          </TableCell>
        </TableRow>
      </Desktop>

      <DuplicateFormDialog
        isDuplicateDialogOpen={isDuplicateDialogOpen}
        setIsDuplicateDialogOpen={setIsDuplicateDialogOpen}
        formTemplate={formTemplate}
        handleModalClose={handleModalClose}
      />
    </>
  );
}

export default FormTemplatesList;
