import React, { useState, useEffect } from 'react';

import {
  Box,
  Stack,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  IconButton,
  TableContainer,
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import { useSelector } from 'src/redux/store';

import Tip from 'src/components/Tip';
import Scrollbar from 'src/components/Scrollbar';

import SvgIconStyle from '../../components/SvgIconStyle';
import AccessGroupsAddDialog from './AccessGroupsAddDialog';
import useNotifications from '../../hooks/useNotifications';
import AdminPanelBackButton from '../admin/AdminPanelBackButton';
import { AccessGroupListItem } from '../../@types/access-groups/types';
import { getAccessGroup, deleteAccessGroup } from '../../services/accessGroupsService';
import { setAccessGroupList, setReloadAccessGroupListState } from '../../redux/slices/accessGroup';

// ----------------------------------------------------------------------

const AccessGroupsList = () => {
  const { t } = useLocales();
  const { accessGroupList, reloadAccessGroupListState } = useSelector((state) => state.accessGroup);
  const [selectedGroup, setSelectedGroup] = useState<AccessGroupListItem | null>(null);

  const [isAccessGroupsAddDialogOpen, setIsAccessGroupsAddDialogOpen] = useState<boolean>(false);
 const isMobile = useResponsive('only', 'xs');

  const getAccessGroupsList = async () => {
    try {
      const list = await getAccessGroup();
      setAccessGroupList(list);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddAccessGroupClick = () => {
    setSelectedGroup(null);

    setIsAccessGroupsAddDialogOpen(true);
  };

  useEffect(() => {
    if (reloadAccessGroupListState !== 0) {
      getAccessGroupsList();
    }
  }, [reloadAccessGroupListState]);

  useEffect(() => {
    getAccessGroupsList();
  }, []);

  // eslint-disable-next-line react/no-unstable-nested-components
  const TableHeader = () => (
    <Stack px={1} py={2} direction={isMobile ? "column" : "row"} justifyContent="space-between" >
      <CardHeader title={t('accessGroups.accessGroups')} sx={{p: 0, pb: 1 }} />

      <Button variant="contained" onClick={handleAddAccessGroupClick} id="XD0G2q">
        {t('accessGroups.createAccessGroup')}
      </Button>
    </Stack>
  );

  return (
    <>
      <Box>
        <AdminPanelBackButton />

        {TableHeader()}

        <Scrollbar>
          <TableContainer sx={{ minWidth: 300 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {[t('dlxDrive.dataGrid.columnName')].map((item) => (
                    <TableCell key={item}>{item}</TableCell>
                  ))}

                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {accessGroupList.map((group, index) => (
                  <AccessGroupItem
                    key={index}
                    group={group}
                    setIsAccessGroupsAddDialogOpen={setIsAccessGroupsAddDialogOpen}
                    setSelectedGroup={setSelectedGroup}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Box>

      <AccessGroupsAddDialog
        isAccessGroupsAddDialogOpen={isAccessGroupsAddDialogOpen}
        setIsAccessGroupsAddDialogOpen={setIsAccessGroupsAddDialogOpen}
        group={selectedGroup}
      />
    </>
  );
};

// ---- Access Group Item ----

type AccessGroupItemProps = {
  group: AccessGroupListItem;
  setIsAccessGroupsAddDialogOpen: (value: boolean) => void;
  setSelectedGroup: (value: AccessGroupListItem) => void;
};

const AccessGroupItem: React.FC<AccessGroupItemProps> = ({
  group,
  setIsAccessGroupsAddDialogOpen,
  setSelectedGroup,
}) => {
  const { t } = useLocales();
  const { enqueueSuccess } = useNotifications();

  const handleAccessGroupEdit = () => {
    setSelectedGroup(group);

    setTimeout(() => setIsAccessGroupsAddDialogOpen(true), 200);
  };

  const handleAccessGroupDelete = async (id: number) => {
    try {
      await deleteAccessGroup(id);

      enqueueSuccess(t('accessGroups.notifications.accessGroupDeleted'), { groupName: group.name });
    } catch (error) {
      console.error(error);
    } finally {
      setReloadAccessGroupListState();
    }
  };

  return (
    <TableRow className="activeHover" sx={{ cursor: 'pointer' }}>
      <TableCell onClick={handleAccessGroupEdit} id={`${group.id}_lRl9Q8`}>
        {group.name}
      </TableCell>

      <TableCell align="right">
        <Tip titleLocale="shared.buttons.delete">
          <IconButton size="small" onClick={() => handleAccessGroupDelete(group.id)} id="h2mFLh">
            <SvgIconStyle
              src="/icons/icon_trashbin.svg"
              sx={{
                width: '20px',
                height: '20px',
                color: 'inherit',
              }}
            />
          </IconButton>
        </Tip>
      </TableCell>
    </TableRow>
  );
};

export default AccessGroupsList;
