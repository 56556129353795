import fileDownload from 'js-file-download';
import { createSlice } from '@reduxjs/toolkit';

import { mapJsonArray } from 'src/utils/mapUtils';

import { HOST_API } from 'src/config';
import { PagedResponse } from 'src/@types/shared/pagedResponse';
import { DocumentHistoryState } from 'src/@types/documents/types';
import { DocumentHistoryItem } from 'src/@types/documents/documentHistoryItem';
import { DocumentHistoryFilter } from 'src/@types/documents/documentHistoryFilter';

import { dispatch } from '../store';
// utils
import axios from '../../utils/httpClients/axios';

// ----------------------------------------------------------------------

const initialState: DocumentHistoryState = {
  isLoading: false,
  error: null,
  history: null,
};

const slice = createSlice({
  name: 'documentHistory',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.history = initialState.history;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getHistorySuccess(state, action) {
      state.isLoading = false;
      state.history = action.payload;
    },

    getHistoryError(state, action) {
      state.isLoading = false;
      state.history = initialState.history;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function clearHistoryState() {
  return () => {
    dispatch(slice.actions.clearState());
  };
}

export async function getHistory(filter: DocumentHistoryFilter) {
  if (filter.id) {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get<PagedResponse<DocumentHistoryItem>>(
        `${HOST_API}documents/${filter.id}/history`,
        { params: { ...filter } }
      );

      const data = {
        total: response.data.total,
        items: mapJsonArray(response.data.items, DocumentHistoryItem),
      };

      dispatch(slice.actions.getHistorySuccess(data));
    } catch (error) {
      dispatch(slice.actions.getHistoryError(error));
    }
  } else {
    dispatch(slice.actions.getHistoryError('documents.errors.historyNotFound'));
  }
}

export async function confirmDownload(id: number | null) {
  if (!id) {
    return;
  }

  try {
    await axios.post(`${HOST_API}documents/${id}/confirm-download`);
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export async function confirmAccDocumentDownload(id: number, accDocument: string) {
  const model = {
    id,
    accDocument,
  };

  try {
    await axios.post(`${HOST_API}documents/confirm-acc-download`, model);
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export async function downloadHistory(id: number | null) {
  if (!id) {
    return;
  }
  try {
    const d = new Date();
    const dateName = `${d.toISOString().split('T')[0]}.pdf`;

    const response = await axios.post(
      `${HOST_API}documents/documentHistoryExport?documentId=${id}`,
      null,
      { responseType: 'blob' }
    );

    const blob = new Blob([response.data]);
    const file = new File([blob], dateName, { type: 'application/pdf' });

    fileDownload(file, dateName);
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}
