// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_HR = '/hr';
const ROOTS_ACCOUNTING = '/accounting';
const ROOTS_AUTH = '/auth';
const ROOTS_STANDARD = '/standard';
const ROOTS_ADMIN = '/admin';
const ROOTS_PROFILE = '/profile';
const ROOTS_ADDRESSBOOK = '/addressBook';
const ROOTS_SUPERADMIN = '/super-admin';
const ROOTS_DOCUMENTTREE = '/document-tree';
const ROOTS_DLXDRIVE = '/folders';
const ROOTS_CALENDAR = '/calendar';

const ROOT_ADOC = 'https://adoc.lt/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  passwordRecovery: path(ROOTS_AUTH, '/password-recovery'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_HR = {
  root: ROOTS_HR,
  documents: `${ROOTS_HR}/documents`,
  createDocument: `${ROOTS_HR}/create-document`,
  createDocumentFormIdFolderId: (formBuilderId: string | number, folderId: string | number) =>
    `${ROOTS_HR}/create-document/${formBuilderId}/${folderId}`,
  documentsId: (id: string | number) => `${ROOTS_HR}/documents/${id}`,
  documentsEdit: (id: string | number) => `${ROOTS_HR}/documents/${id}/edit`,
};

export const PATH_ACCOUNTING = {
  root: ROOTS_ACCOUNTING,
  documents: `${ROOTS_ACCOUNTING}/documents`,
  createDocument: `${ROOTS_ACCOUNTING}/create-document`,
  createDocumentFormIdFolderId: (formBuilderId: string | number, folderId: string | number) =>
    `${ROOTS_ACCOUNTING}/create-document/${formBuilderId}/${folderId}`,
  documentsId: (id: string | number) => `${ROOTS_ACCOUNTING}/documents/${id}`,
  documentsEdit: (id: string | number) => `${ROOTS_ACCOUNTING}/documents/${id}/edit`,
};

export const PATH_STANDARD = {
  root: ROOTS_STANDARD,
  documents: `${ROOTS_STANDARD}/documents`,
  createDocument: `${ROOTS_STANDARD}/create-document`,
  composeDocument: `${ROOTS_STANDARD}/compose-document`,
  composeDocumentFolderId: (id: string | number) => `${ROOTS_STANDARD}/compose-document/${id}`,
  documentsId: (id: string | number) => `${ROOTS_STANDARD}/documents/${id}`,
  documentsEdit: (id: string | number) => `${ROOTS_STANDARD}/documents/${id}/edit`,
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  adminPanel: path(ROOTS_ADMIN, '/admin-panel'),
  users: path(ROOTS_ADMIN, '/users'),
  permissions: path(ROOTS_ADMIN, '/permissions'),
  company: path(ROOTS_ADMIN, '/company'),
  emailTemplates: path(ROOTS_ADMIN, '/email-templates-settings'),
  billingReport: path(ROOTS_ADMIN, '/billing-report'),
  templates: path(ROOTS_ADMIN, '/templates'),
  templatesCreate: path(ROOTS_ADMIN, '/templates/create'),
  tags: path(ROOTS_ADMIN, '/tags'),
  jobTitles: path(ROOTS_ADMIN, '/job-titles'),
  formEdit: (id: string | number) => `${ROOTS_ADMIN}/templates/forms/${id}`,
  departments: path(ROOTS_ADMIN, '/departments'),

  // Forms
  forms: path(ROOTS_ADMIN, '/forms'),
  formsCreate: path(ROOTS_ADMIN, '/forms/create'),
  formsEdit: (id: number) => `${ROOTS_ADMIN}/forms/edit/${id}`,
  customList: path(ROOTS_ADMIN, '/lists'),

  // Folders
  accessGroups: path(ROOTS_ADMIN, '/access-groups'),

  // Calendar
  eventTypes: path(ROOTS_ADMIN, '/event-types'),
};

export const PATH_SUPERADMIN = {
  root: ROOTS_SUPERADMIN,
  tenants: path(ROOTS_SUPERADMIN, '/tenants'),
  tenantCreate: path(ROOTS_SUPERADMIN, '/tenants/create'),
  tenantEdit: (id: string | number) => `${ROOTS_SUPERADMIN}/tenants/${id}`,
};

export const PATH_PROFILE = {
  root: ROOTS_PROFILE,
};

export const PATH_ADDRESSBOOK = {
  root: ROOTS_ADDRESSBOOK,
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
};

export const PATH_DOCUMENT_TREE = {
  root: ROOTS_DOCUMENTTREE,
};

export const PATH_DLX_DRIVE = {
  root: ROOTS_DLXDRIVE,
  folder: (id: number | null, page: number | null) => path(ROOTS_DLXDRIVE, `/${id}/${page}`),
};

export const PATH_CALENDAR = {
  root: ROOTS_CALENDAR,
};

export const PATH_ADOC_BUSINESS = path(ROOT_ADOC, 'verslui/');

export const PATH_ADOC = path(ROOT_ADOC, '#pasiūlymai-verslui');
