import React, { useRef, useState, useEffect } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Tooltip, Checkbox, useTheme, TextField, Autocomplete } from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import './elements-vanilla-mobile/address-book-mobile.scss';
import { setErrorList } from '../../../redux/slices/formBuilder';
import { GroupedUsersProps } from '../fill-form/BuilderFormFiller';
import validateIsRequired from '../../../@types/forms/utils/validateIsRequired';
import { ElementProps, ErrorsListProps, ElementValidationProps } from '../../../@types/forms/types';

//-------------------------------------------------------------

type AddressBookElementProps = {
  el: ElementProps;
  groupedUsers: GroupedUsersProps[];
  elementContentValue?: string[] | undefined;
  handleAddressBookChange: (value: GroupedUsersProps[], el: ElementProps) => void;
  pageNumberScale: number;
};

const AddressBookElement: React.FC<AddressBookElementProps> = ({
  el,
  groupedUsers,
  elementContentValue,
  handleAddressBookChange,
  pageNumberScale,
}) => {
  const { t } = useLocales();
  const [value, setValue] = useState<{ firstLetter: string; name: string }[] | undefined>(
    undefined
  );
  const [elementErrorsList, setElementErrorsList] = useState<ErrorsListProps[]>([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const theme = useTheme();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const labelRef = useRef<HTMLSpanElement | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const data: ElementValidationProps = {
      el,
      errorMessage: '',
      value: value?.flat().toString(),
      elementErrorsList,
    };
    const newErrorList: ErrorsListProps[] = [];

    if (el.required) {
      const newError = validateIsRequired({
        ...data,
        errorMessage: t('forms.validationErrorMessage.fieldRequired'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    setElementErrorsList(newErrorList);
  }, [value]);

  useEffect(() => {
    if (elementErrorsList) {
      setErrorList({ elementID: el.id, errors: elementErrorsList });
    }
  }, [elementErrorsList]);

  useEffect(() => {
    if (elementContentValue) {
      setValue(elementContentValue.map((u) => ({ firstLetter: u[0], name: u })));
    }
  }, []);

  useEffect(() => {
    if (labelRef.current && inputRef.current) {
      const labelWidth = labelRef.current.offsetWidth;
      const inputWidth = inputRef.current.offsetWidth;
      setShowTooltip(labelWidth > inputWidth);
    }
  }, [el.label]);

  const handleFocus = () => {
    if(showTooltip){
      setTooltipOpen(true);
    }
  };

  const handleBlur = () => {
    setTooltipOpen(false);
  };

  return (
    <Box sx={{ m: 0, p: 0, width: '100%' }} id={el.id}>
      <Tooltip 
        title={el.label}
        open={tooltipOpen}
        onClose={handleBlur}
        placement="top-end"
        disableHoverListener
      >
        <Autocomplete
          ref={inputRef}
          multiple
          popupIcon={<ExpandMoreRoundedIcon />}
          defaultValue={
            elementContentValue
              ? elementContentValue.map((u) => ({ firstLetter: u[0], name: u }))
              : []
          }
          limitTags={1}
          fullWidth
          sx={{
            backgroundColor: theme.palette.info.lighter,
            borderRadius: 1,
          }}
          id={`${el.id}`}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={(event, newValue) => {
            handleAddressBookChange(newValue, el);
            setValue(newValue);
          }}
          options={
            groupedUsers
              ? groupedUsers.sort((a, b) => a.firstLetter.localeCompare(b.firstLetter))
              : []
          }
          groupBy={(option) => option.firstLetter}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={`${props.id}`} className="li-line">
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4 }}
                checked={selected}
              />
              {option.name}
            </li>
          )} 
          renderInput={(params) => (
            <TextField
              error={elementErrorsList.length > 0}
              helperText={elementErrorsList.length > 0 ? elementErrorsList[0].errorMessage : null}
              variant="standard"
              {...params}
              placeholder={t(`users.selectedUsers`)}
              label={
                <span ref={labelRef}>
                  {el.label}
                </span>
              }
              id="0ahv1hiq"
            />
          )}
        />
      </Tooltip>
     
    </Box>
  );
};

export default AddressBookElement;
