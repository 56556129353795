import React from 'react';
import { useNavigate } from 'react-router';

import { Box, Card, useTheme, Typography } from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import { PATH_STANDARD } from '../../../routes/paths';
import useResponsive from '../../../hooks/useResponsive';
import { getFileType } from '../../../utils/getFileFormat';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { UploadSingleFile } from '../../../components/upload';
import { FileExtensions } from '../../../@types/fileView/enums/fileExtensions';

// --------------------------------------------------------

export const AVAILABLE_EXTENSIONS: string[] = [
  FileExtensions.Pdf,
  FileExtensions.Adoc,
  FileExtensions.Asic,
  FileExtensions.Bdoc,
  FileExtensions.Edoc,
  FileExtensions.Docx,
  FileExtensions.Pptx,
  FileExtensions.Xlsx,
];

type DLXDriveDocumentActionsUploadDocumentProps = {
  folderId: number;
};

const DLXDriveDocumentActionsUploadDocument: React.FC<
  DLXDriveDocumentActionsUploadDocumentProps
> = ({ folderId }) => {
  const { t } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useResponsive('only', 'xs');

  const handleDrop = (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      return;
    }
    navigate(`${PATH_STANDARD.composeDocument}/${folderId}`, {
      state: !acceptedFiles.length ? null : acceptedFiles,
    });
  };

  const docTypeValidator = (file: File) => {
    const extension = getFileType(file.name);
    if (!AVAILABLE_EXTENSIONS.includes(extension)) {
      return {
        code: 'file-invalid-type',
        message: 'components.uploadSingleFile.fileMustBe',
        parameters: { data: AVAILABLE_EXTENSIONS.join(', ') },
      };
    }

    return null;
  };

  return (
    <Card
      sx={{
        width: isMobile ? '100%' : '360px',
        px: isMobile ? 1.5 : 3,
        py: isMobile ? 1.5 : 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
      }}
    >
      <Box flexGrow={1} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'}>
          {t('dlxDrive.buttons.uploadDocument')}
        </Typography>

        {!isMobile && (
          <Typography variant="body2" color={theme.palette.text.secondary}>
            <Typography
              variant="inherit"
              component="span"
              color={theme.palette.primary.main}
              sx={{ textDecoration: 'underline' }}
            >
              {t('documents.choose')}
            </Typography>
            {t('documents.docOrDrag')}
          </Typography>
        )}
      </Box>

      <Box>
        <SvgIconStyle
          src="/icons/dlx-drive/upload_document.svg"
          sx={{
            width: 54 ,
            height:  54 ,
            display: 'inherit',
            opacity: '0.5',
            color: theme.palette.grey[600]
          }}
        />
      </Box>

      <UploadSingleFile
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          opacity: 0,
        }}
        noText
        accept={AVAILABLE_EXTENSIONS.map((x) => `.${x}`).join(', ')}
        onDrop={handleDrop}
        validator={docTypeValidator}
      />
    </Card>
  );
};

export default DLXDriveDocumentActionsUploadDocument;
