import { useState, useEffect, ChangeEvent } from 'react';

import {
  Box,
  Menu,
  Tooltip,
  Checkbox,
  useTheme,
  FormGroup,
  TableCell,
  FormControlLabel,
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import { SortedTableCell } from 'src/@types/shared/sortedTableCell';
import { FilteringItemModel, FilteringValueType } from 'src/@types/filtering/types';

import Iconify from 'src/components/Iconify';

import { useSelector } from '../../redux/store';
import { SelectedItemProp } from '../../sections/documents/DocumentsList';

//--------------------------------------------------------

type SortingState = {
  column: number | null;
  type: number;
};

type SortedHeaderProps = {
  columns: SortedTableCell[];
  definedFilters?: FilteringItemModel[];
  handleSortingChange?: (column: number | null, sortDescending: boolean) => void;
  handleFiltersChange?: (filter: {}) => void;
  refreshTrigger?: number;
  isDownload: boolean;
  isCardView?: boolean;
  onDownloadFile?: () => void;
  selectedIds?: SelectedItemProp[];
  setSelectedIds?: (selectedIds: SelectedItemProp[]) => void;
  selectAllChecked?: boolean;
  setSelectAllChecked?: (checked: boolean) => void;
};

export default function SortedHeader({
  columns,
  definedFilters,
  handleSortingChange,
  handleFiltersChange,
  refreshTrigger,
  isDownload,
  isCardView,
  onDownloadFile,
  selectedIds,
  setSelectedIds,
  selectAllChecked,
  setSelectAllChecked,
}: SortedHeaderProps) {
  const { t } = useLocales();
  const { documents } = useSelector((state) => state.document);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<{ anchor: HTMLElement; id: string }>();
  const [sorting, setSorting] = useState({ column: null, type: -1 } as SortingState);
  const [filters, setFilters] = useState<FilteringItemModel[]>([]);
  const [columnId, setColumnId] = useState<string>();
  const [columnName, setColumnName] = useState<string>();
  const [activeItemSt, setActiveItemSt] = useState<string[]>([]);
  const isMobile = useResponsive('only', 'xs');
  const isLight = theme.palette.mode === 'light';

  useEffect(() => {}, [columnId, filters, columnName]);

  useEffect(() => {
    if (definedFilters) {
      setFilters([...definedFilters]);
    }
  }, [definedFilters]);

  const handleChange = (
    column: number | null,
    columnId: string | null,
    columnName: string | null,
    colFilterName: string | null
  ) => {
    if (columnName && columnId) {
      setColumnName(columnName);
      setColumnId(columnId);
    }

    if (sorting.column !== column) {
      setSorting({ column, type: 0 });
      sortAddedColumn(columnId, columnName, colFilterName);
    } else {
      let type = sorting.type + 1;

      if (type > 1) {
        type = -1;
        column = null;
      }

      setSorting({ column, type });
      sortAddedColumn(columnId, columnName, colFilterName);
    }
  };

  const sortAddedColumn = (
    columnId: string | null,
    columnName: string | null,
    colFilterName: string | null
  ) => {
    if (columnId !== null && columnName !== null && colFilterName !== null) {
      handleFilterChange('formColumnFilter', { columnId, formName: columnName, value: '' });
    }
  };

  const handleAddColumnData = (colName: string, colId: string) => {
    setColumnName(colName);
    setColumnId(colId);
  };

  const handleFilterChange = (name: string, value?: FilteringValueType) => {
    const newFilters = filters.filter((f) => f.name !== name);

    if (value) {
      newFilters.push({ name, value });
    }
    setFilters(newFilters);
  };

  useEffect(() => {
    if (handleSortingChange) {
      handleSortingChange(sorting.column, sorting.type === 1);
    }
  }, [sorting.type, sorting.column]);

  useEffect(() => {
    const filterObject = filters.reduce((o, k) => ({ ...o, [k.name]: k.value }), {});

    if (handleFiltersChange) {
      handleFiltersChange(filterObject);
    }
  }, [filters]);

  useEffect(() => {
    setFilters([]);
    setSorting({ column: null, type: -1 });
    setActiveItemSt([]);
  }, [refreshTrigger]);

  const getSortDirection = (column: number, actionColumnId: string | null) => {
    if (actionColumnId && actionColumnId === columnId) {
      // for added columns
      if (sorting.column === 4 && sorting.column === column) {
        switch (sorting.type) {
          case 0:
            return <Iconify icon="eva:arrow-upward-outline" sx={{ cursor: 'pointer', ml: 1 }} />;
          case 1:
            return <Iconify icon="eva:arrow-downward-outline" sx={{ cursor: 'pointer', ml: 1 }} />;
          default:
            return null;
        }
      }
    } else {
      // for default columns
      if (sorting.column !== 4 && sorting.column === column) {
        switch (sorting.type) {
          case 0:
            return <Iconify icon="eva:arrow-upward-outline" sx={{ cursor: 'pointer', ml: 1 }} />;
          case 1:
            return <Iconify icon="eva:arrow-downward-outline" sx={{ cursor: 'pointer', ml: 1 }} />;
          default:
            return null;
        }
      }
    }

    return (
      <Iconify
        icon="eva:swap-outline"
        sx={{ transform: 'rotate(90deg)', cursor: 'pointer', ml: 1 }}
        className="default-sorting-icon"
      />
    );
  };

  const getSortableIcon = (
    column: number,
    columnId: string | null,
    columnName: string | null,
    colFilterName: string | null
  ) => (
    <Box
      sx={{ display: 'flex', alignItems: 'center' }}
      onClick={() => handleChange(column, columnId, columnName, colFilterName)}
    >
      {getSortDirection(column, columnId)}
    </Box>
  );

  const handleOpenFilter = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    setAnchorEl({ anchor: e.currentTarget, id });
  };

  const getTitle = (title: string | JSX.Element) => {
    if (typeof title === 'string') {
      return <>{t(title)}</>;
    }

    return <>{title}</>;
  };

  const handleActiveField = (col: SortedTableCell) => {
    if (activeItemSt.length > 0 && col.id && activeItemSt.includes(col.id)) {
      setActiveItemSt(activeItemSt.filter((item) => item !== col.id));
    } else if (col.id) {
      setActiveItemSt([...activeItemSt, col.id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedIds && setSelectedIds) {
      const newSelectedIds = [...selectedIds];

      documents?.items?.forEach((document) => {
        const isDocumentSelected = newSelectedIds.some((item) => item.id === document.id);

        if (!isDocumentSelected) {
          newSelectedIds.push({
            id: document.id,
            isFolder: false,
            name: document.name,
            status: document.status,
            extension: document.extension,
          });
        }
      });

      setSelectedIds(newSelectedIds);
    }
  };

  const handleUnSelectAll = () => {
    if (selectedIds && setSelectedIds) {
      const newSelectedIds = [...selectedIds];

      documents?.items?.forEach((document) => {
        const documentIndex = newSelectedIds.findIndex((item) => item.id === document.id);

        if (documentIndex !== -1) {
          newSelectedIds.splice(documentIndex, 1);
        }
      });

      setSelectedIds(newSelectedIds);
    }
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (setSelectAllChecked) {
      if (checked) {
        setSelectAllChecked(true);

        handleSelectAll();
      } else {
        setSelectAllChecked(false);

        handleUnSelectAll();
      }
    }
  };

  return (
    <>
      {!isCardView && (
        <>
          {isDownload && (
            <TableCell
              sx={{
                maxWidth: '60px',
                backgroundColor: isLight ? theme.palette.grey[400] : theme.palette.grey[700],
              }}
            >
              <FormGroup sx={{ ml: '3px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAllChecked}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleSelectAllClick(event.target.checked);
                      }}
                    />
                  }
                  label=""
                />
              </FormGroup>
            </TableCell>
          )}

          {columns.map((col, index) => (
            <TableCell
              key={index}
              sx={{
                MozUserSelect: 'none',
                WebkitUserSelect: 'none',
                msUserSelect: 'none',
                userSelect: 'none',
                ...(!!col.filter && { cursor: 'pointer' }),
                backgroundColor: isLight ? theme.palette.grey[400] : theme.palette.grey[700],
                color: isLight ? theme.palette.grey[700] : theme.palette.grey[300],
              }}
            >
              <Tooltip title={col.name !== undefined ? col.name : ''} placement="top-start">
                <Box sx={{ display: 'flex', justifyContent: index === columns.length - 1 ? 'flex-end' : 'flex-start' }} onClick={() => handleActiveField(col)}>
                
                    {!!col.filter && (
                      <>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center' }}
                          id={`filter-button${index}`}
                          onClick={(e) => handleOpenFilter(e, `filter-menu${index}`)}
                        >
                          <Box
                            sx={{
                              whiteSpace: 'pre-wrap',
                              lineHeight: '1.2rem'
                            }}
                          >
                            {getTitle(col.title)}
                          </Box>
                          <Iconify
                            icon="eva:chevron-down-fill"
                            sx={{ width: '24px', height: '24px', mr: -1 }}
                          />
                        </Box>
                        <Menu
                          id={`filter-menu${index}`}
                          anchorEl={anchorEl?.anchor}
                          open={anchorEl?.id === `filter-menu${index}`}
                          onClose={() => setAnchorEl(undefined)}
                        >
                          <Box sx={{ p: 2 }}>
                            <col.filter.component
                              key={`${col.title}-${index}`}
                              name={col.filter.name}
                              formName={col.name !== undefined ? col.name : undefined}
                              value={filters.find((f) => f.name === col.filter?.name)?.value}
                              columnId={col.id ? col.id : undefined}
                              onChange={handleFilterChange}
                            />
                          </Box>
                        </Menu>
                      </>
                    )}
                    {!col.filter && (
                      <Box
                        lang="en"
                        sx={{
                          px: 0,
                          mx: 0,
                          maxWidth: 'inherit',
                          lineHeight: '1.2rem'
                        }}
                      >
                        {getTitle(col.title)}
                      </Box>
                    )}

                    {col.sortingKey !== undefined &&
                      col.sortingKey !== null &&
                      getSortableIcon(
                        col.sortingKey,
                        col.id ? col.id : null,
                        col.name ? col.name : null,
                        col.filter !== undefined ? col.filter.name : null
                      )}
                 
                </Box>
              </Tooltip>
            </TableCell>
          ))}
        </>
      )}

      {isCardView && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: isMobile ? 1.5 : 2,
            width: '100%',
            color: theme.palette.grey[700],
            mb: 2,
            mt: 1,
            fontSize: isMobile ? '14px' : 'inherit',
          }}
        >
          {columns.map((col, index) =>  (
                <Box
                  key={index}
                  sx={{
                    MozUserSelect: 'none',
                    WebkitUserSelect: 'none',
                    msUserSelect: 'none',
                    userSelect: 'none',
                    ...(!!col.filter && { cursor: 'pointer' }),
                    height: '40px',
                    px: isMobile ? 1 : 2,
                    borderRadius: 0.8,
                    fontWeight: 600,
                    color: isLight ? theme.palette.grey[700] : theme.palette.grey[300],
                    backgroundColor: isLight ? theme.palette.grey[400] : theme.palette.grey[700],
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Tooltip title={col.name !== undefined ? col.name : ''} placement="top-start">
                    <Box
                      sx={{ display: 'flex' }}
                      onClick={() =>
                        col.id !== undefined &&
                        col.name !== undefined &&
                        handleAddColumnData(col.name, col.id)
                      }
                    >
                      {!!col.filter && (
                        <>
                          <Box
                            sx={{ display: 'flex', alignItems: 'center' }}
                            id={`filter-button${index}`}
                            onClick={(e) => handleOpenFilter(e, `filter-menu${index}`)}
                          >
                            <Box sx={{ whiteSpace: 'nowrap' }}>{getTitle(col.title)}</Box>
                            <Iconify
                              icon="eva:chevron-down-fill"
                              sx={{ width: '32px', height: '32px', mr: -1 }}
                            />
                          </Box>

                          <Menu
                            id={`filter-menu${index}`}
                            anchorEl={anchorEl?.anchor}
                            open={anchorEl?.id === `filter-menu${index}`}
                            onClose={() => setAnchorEl(undefined)}
                          >
                            <Box>
                              <col.filter.component
                                key={`${col.title}-${index}`}
                                name={col.filter.name}
                                formName={col.name !== undefined ? col.name : undefined}
                                value={filters.find((f) => f.name === col.filter?.name)?.value}
                                columnId={col.id ? col.id : undefined}
                                onChange={handleFilterChange}
                              />
                            </Box>
                          </Menu>
                        </>
                      )}
                      {!col.filter && <>{getTitle(col.title)}</>}

                      {col.sortingKey !== undefined &&
                        col.sortingKey !== null &&
                        getSortableIcon(
                          col.sortingKey,
                          col.id ? col.id : null,
                          col.name ? col.name : null,
                          col.filter !== undefined ? col.filter.name : null
                        )}
                    </Box>
                  </Tooltip>
                </Box>
              )
              
            
        
        )
          }
        </Box>
      )}
    </>
  );
}
