import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Tab, Tabs, Dialog, IconButton, DialogContent } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import DLXDriveChangeFolderIconDialog from './DLXDriveChangeFolderIconDialog';
import DLXDriveChangeFolderColorDialog from './DLXDriveChangeFolderColorDialog';

// -------------------------------------------

enum TabValues {
  FolderIcons = 0,
  FolderColors = 1,
}

type CustomTabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

type DLXDriveChangeFolderColorAndIconDialogProps = {
  folderId: number;
  folderName: string;
  folderColor: string;
  folderIcon: string;
  isChangeFolderColorAndIconDialogOpen: boolean;
  setIsChangeFolderColorAndIconDialogOpen: (value: boolean) => void;
};

const DLXDriveChangeFolderColorAndIconDialog: React.FC<
  DLXDriveChangeFolderColorAndIconDialogProps
> = ({
  folderId,
  folderName,
  folderColor,
  folderIcon,
  isChangeFolderColorAndIconDialogOpen,
  setIsChangeFolderColorAndIconDialogOpen,
}) => {
  const { t } = useLocales();
  const [tabValue, setTabValue] = useState<TabValues>(TabValues.FolderIcons);

  const handleClose = () => {
    setIsChangeFolderColorAndIconDialogOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabValues) => {
    setTabValue(newValue);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function CustomTabPanel(props: CustomTabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Box>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Dialog
      open={isChangeFolderColorAndIconDialogOpen}
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
    >
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label={t('dlxDrive.folderSettings.icon')} {...a11yProps(TabValues.FolderIcons)} />
            <Tab
              label={t('dlxDrive.folderSettings.color')}
              {...a11yProps(TabValues.FolderColors)}
            />
          </Tabs>

          <IconButton size="small" onClick={handleClose} id="om27r8t3">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <CustomTabPanel value={tabValue} index={TabValues.FolderIcons}>
          <DLXDriveChangeFolderIconDialog
            folderId={folderId}
            folderName={folderName}
            folderColor={folderColor}
            folderIcon={folderIcon}
            setIsChangeFolderColorAndIconDialogOpen={setIsChangeFolderColorAndIconDialogOpen}
          />
        </CustomTabPanel>

        <CustomTabPanel value={tabValue} index={TabValues.FolderColors}>
          <DLXDriveChangeFolderColorDialog
            folderId={folderId}
            folderName={folderName}
            folderColor={folderColor}
            folderIcon={folderIcon}
            setIsChangeFolderColorAndIconDialogOpen={setIsChangeFolderColorAndIconDialogOpen}
          />
        </CustomTabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default DLXDriveChangeFolderColorAndIconDialog;
